import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, usePrevious } from 'suits-me-npm/utils';

import { fetchCards, updateSelectedAccount } from 'suits-me-npm/actions';
import {
    getAccountIsPosting,
    getAccountPostSuccess,
    getPostTokenSuccess,
} from 'suits-me-npm/selectors';
import { hideModal } from 'actions/modal';

const useAccountSelect = (accounts, account) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [form, handleChange] = useForm({
        ppsAccountNo: account.ppsAccountNo,
    });

    const postSuccess = useSelector(getAccountPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const isPosting = useSelector(getAccountIsPosting);
    const jwt = useSelector(getPostTokenSuccess);

    const formattedAccountOptions = accounts.map(account => {
        const isEuro = account.productClassCode.includes('EUR');

        return {
            label: isEuro
                ? `${account.iban}`
                : `${account.bankAccountNo} - ${account.sortCode}`,
            value: account.ppsAccountNo,
        };
    });

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            dispatch(hideModal());
            history.push('/');
            dispatch(fetchCards());

            if (jwt) {
                localStorage.setItem('token', jwt);
            }
        }
    }, [postSuccess, prevPostSuccess]);

    const handleSubmit = () => {
        const { ppsAccountNo } = form;

        if (ppsAccountNo) {
            dispatch(updateSelectedAccount(ppsAccountNo));
        }
    };

    return {
        form,
        handleChange,
        formattedAccountOptions,
        handleSubmit,
        isPosting,
    };
};

export default useAccountSelect;
