import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    twCreateTemporaryQuote,
    twCreateQuote,
    twUpdateQuote,
} from 'suits-me-npm/actions';
import { isEmpty } from 'suits-me-npm/utils';
import {
    getTwQuoteIsPosting,
    getTwTemporaryQuote,
    getTwQuote,
} from 'suits-me-npm/selectors';

import { getTwTargetCurrency } from 'selectors/tw-quote';
import useDebounce from 'utils/hooks/useDebounce';
import {
    PopularCurrienciesList,
    AllCurrienciesList,
} from 'constants/shared/currencies';
import { SEARCH_AND_LIST_MODAL } from 'constants/shared/modalTypes';
import { dispatchShowModal, dispatchHideModal } from 'utils/modals';
import { setTargetCurrency } from 'actions/tw-quote';
import { searchCurrencies } from 'utils/generic';

export default (temporary, storedQuote) => {
    const dispatch = useDispatch();
    const [sourceAmount, setSourceAmount] = useState(10);
    const debouncedValue = useDebounce(sourceAmount, 400);
    const tempQuote = useSelector(getTwTemporaryQuote);
    const quote = useSelector(temporary ? getTwTemporaryQuote : getTwQuote);
    const quoteIsFetching = useSelector(getTwQuoteIsPosting);
    const targetCurrency = useSelector(getTwTargetCurrency);

    const handleSourceAmount = useCallback(
        event => setSourceAmount(event.target.value),
        [setSourceAmount],
    );

    const handleSetRecipientCurrency = item =>
        dispatch(setTargetCurrency(item));

    const onSearchChange = searchTerm => {
        return searchCurrencies(
            AllCurrienciesList,
            ['name', 'currency_code'],
            searchTerm,
        );
    };

    const handleFetchTemporaryQuote = () => {
        dispatch(
            twCreateTemporaryQuote({
                targetCurrency,
                sourceAmount: Number(sourceAmount),
            }),
        );
    };

    const handleCreateQuote = () => {
        const amount = !isEmpty(storedQuote)
            ? storedQuote.sourceAmount
            : tempQuote.sourceAmount;
        const currency = !isEmpty(storedQuote)
            ? storedQuote.targetCurrency
            : targetCurrency;
        dispatch(
            twCreateQuote({
                targetCurrency: currency,
                sourceAmount: Number(amount),
            }),
        );
    };

    const handleUpdateQuote = () => {
        dispatch(
            twUpdateQuote(quote.id, {
                targetCurrency,
                sourceAmount: Number(sourceAmount),
            }),
        );
    };

    const handleClick = item => {
        handleSetRecipientCurrency(item.currency_code);
        handleCloseModal();
    };

    const handleCloseModal = () => dispatchHideModal(dispatch);

    const handleShowModal = () =>
        dispatchShowModal(dispatch, SEARCH_AND_LIST_MODAL, {
            handleClick,
            handleCloseModal,
            onSearchChange,
            popularList: PopularCurrienciesList,
            othersList: AllCurrienciesList,
        });

    useEffect(() => {
        if (!isEmpty(storedQuote)) {
            setSourceAmount(Number(storedQuote.sourceAmount));
        } else if (!isEmpty(tempQuote)) {
            setSourceAmount(tempQuote.sourceAmount);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const amount = Number(sourceAmount);
        if (temporary && amount) {
            handleFetchTemporaryQuote();
        } else if (!temporary) {
            if (isEmpty(quote)) {
                handleCreateQuote();
            } else {
                handleUpdateQuote();
            }
        }
        // eslint-disable-next-line
    }, [debouncedValue, targetCurrency]);

    return {
        quote,
        quoteIsFetching,
        targetCurrency,
        sourceAmount,
        handleSourceAmount,
        handleShowModal,
    };
};
