const dev = require('./dev.config');
const staging = require('./staging.config');
const stagingEuro = require('./stagingEuro.config');
const production = require('./production.config');

const env = process.env.REACT_APP_ENVIRONMENT || 'dev';

const configs = {
    dev,
    staging,
    stagingEuro,
    production,
};

module.exports = configs[env];
