module.exports = {
    // API_URL: 'https://localhost:5001/api',
    API_URL:
        'http://suitsme-env.eba-yz3p87rr.eu-west-1.elasticbeanstalk.com/api',
    RAW_S3_STORAGE_URL: '',
    TW_CLIENT_ID: 'suitsme',
    TW_REDIRECT_URI: 'https://localhost:3000/international/check',
    TW_AUTH_LINK_URL: 'https://sandbox.transferwise.tech/oauth/authorize',
    CAPTCHA_SITE_KEY: '6Lcmub8bAAAAAPFEFSXyv1gDgfXUixXl5k1LlNyQ',
    CAPTCHA_SECRET_KEY: '6Lcmub8bAAAAABp5uA062f5k71UCeM7lweyp50ts',
};
