import React from 'react';
import { useHistory } from 'react-router-dom';

import { TRANSACTIONS } from 'constants/shared/routes';
import usePaymentSuccessful from 'utils/hooks/usePaymentSuccessful';

import Container from 'components/shared/generic/container/presentational/Container';
import PaymentSuccess from '../presentational/PaymentSuccess';

const PaymentSuccessContainer = () => {
    const history = useHistory();
    const { paymentSuccessful, setPaymentSuccessful } = usePaymentSuccessful();

    const handleSuccessClick = () => {
        setPaymentSuccessful({});
        history.push(TRANSACTIONS);
    };

    return (
        <Container fullHeight flexCenter>
            <PaymentSuccess onSuccessClick={handleSuccessClick} data={paymentSuccessful} />
        </Container>
    );
};

export default PaymentSuccessContainer;
