import React from 'react';
import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import TermsAndConditions from '../presentational/TermsAndConditions';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TermsAndConditionsContainer = () => {
    const translate = useTranslate('termsAndConditions');
    return (
        <PageContainer className="more-terms-and-conditions-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={MORE}
            />
            <TermsAndConditions />
        </PageContainer>
    );
};

export default TermsAndConditionsContainer;
