import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPayees } from 'suits-me-npm/actions';
import {
    getPayees,
    getPayeesIsFetching,
    getPayeesError,
} from 'suits-me-npm/selectors';
import {
    PAYMENT_STANDING_ORDER,
    PAYMENT_STANDING_ORDER_FORM,
    PAYMENT_STANDING_ORDER_PAYEE,
} from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PayeeInfiniteScrollContainer from 'components/shared/layout/payeeInfiniteScroll/containers/PayeeInfiniteScrollContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';

const StandingOrderPayeeContainer = () => {
    const dispatch = useDispatch();
    const payee = useSelector(getPayees);
    const isFetching = useSelector(getPayeesIsFetching);
    const error = useSelector(getPayeesError);

    useEffect(() => {
        dispatch(fetchPayees(1));
    }, []);

    return (
        <PageContainer>
            <SectionTitle
                title="Standing Order Payee"
                backRoute={PAYMENT_STANDING_ORDER}
            />
            <DataCheck
                data={payee}
                isLoading={isFetching}
                error={error}
                renderIfNotEmpty
                noDataMessage="You currently have no payees"
            >
                <PayeeInfiniteScrollContainer
                    backRoute={PAYMENT_STANDING_ORDER_PAYEE}
                    nextRoute={PAYMENT_STANDING_ORDER_FORM}
                />
            </DataCheck>
        </PageContainer>
    );
};

export default StandingOrderPayeeContainer;
