import React from 'react';
import { SUITS_ME_BLOCKED_ACCOUNT } from 'constants/shared/routes';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';

const PasswordRecovery = ({
    username,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => (
    <Box>
        <SectionTitle title="Password Retrieval" secondary />
        <div className="statement mb">
            <p>Please enter the associated username with this account</p>
        </div>
        <Form
            submitText="Send Recovery Email"
            buttonSize="full"
            onSubmit={handleSubmit}
            isPosting={isPosting}
            error={error ? 'A problem occurred! Please try again.' : ''}
        >
            <Field required>
                <TextInput
                    required
                    placeholder="Username"
                    onChange={handleChange}
                    name="username"
                    value={username}
                    classes="generic-input"
                />
            </Field>
        </Form>
        <div className="statement">
            Forgotten relevant username?{' '}
            <a href={SUITS_ME_BLOCKED_ACCOUNT}>Please Contact Us</a>
        </div>
    </Box>
);

export default PasswordRecovery;
