import React from 'react';
import { useValidatePassCode } from 'suits-me-npm/hooks';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import ValidatePasscode from '../presentational/ValidatePasscode';

const ValidatePasscodeContainer = ({ isPosting, error }) => {
    const { handleChange, description, handleSubmit } = useValidatePassCode();
    const [getCaptchaToken, loadingCaptcha] = useRecaptcha();
    
    return (
        <ValidatePasscode
            description={description}
            isPosting={isPosting || loadingCaptcha}
            error={error}
            handleChange={handleChange}
            handleSubmit={_handleChange}
        />
    );

    async function _handleChange() {
        const token = await getCaptchaToken();
        handleSubmit(token);
    }
};

export default ValidatePasscodeContainer;
