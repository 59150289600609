import React from 'react';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import InternationalPayees from '../presentational/InternationalPayee';

const InternationalPayeeContainer = () => {
    return (
        <PageContainer className="international-payee-container">
            <InternationalPayees />
        </PageContainer>
    );
};

export default InternationalPayeeContainer;
