import More from 'components/activity/more/main/containers/MoreContainer';
import AccountDetails from 'components/activity/more/accountDetails/containers/AccountDetailsContainer';
import PersonalDetails from 'components/activity/more/personalDetails/containers/PersonalDetailsContainer';
import EditPersonalDetails from 'components/activity/more/editPersonalDetails/containers/EditPersonalDetailsContainer';
import Fees from 'components/activity/more/fees/containers/FeesContainer';
import ManageSubscription from 'components/activity/more/manageSubscription/container/ManageSubscriptionContainer';
import CommunicationPreferences from 'components/activity/more/communicationPreferences/containers/CommunicationPreferencesContainer';
import TermsAndConditions from 'components/activity/more/termsAndConditions/containers/TermsAndConditionsContainer';
import Settings from 'components/activity/more/settings/containers/SettingsContainer';
import ChangePassword from 'components/activity/more/changePassword/containers/ChangePasswordContainer';
import PrivacyPolicy from 'components/activity/more/privacyPolicy/containers/PrivacyPolicyContainer';
import ReferAFriend from 'components/activity/more/referAFriend/presentational/ReferAFriend';
import CashbackRewards from 'components/activity/more/cashbackRewards/presentational/CashbackRewards';

export default [
    { path: '', component: More, pageTitle: 'More' },
    {
        path: 'account',
        component: AccountDetails,
        pageTitle: 'Account Details',
    },
    {
        path: 'personal',
        component: PersonalDetails,
        pageTitle: 'Personal Details',
    },
    {
        path: 'personal/edit',
        component: EditPersonalDetails,
        pageTitle: 'Personal Details',
    },
    {
        path: 'manage-subscription',
        component: ManageSubscription,
        pageTitle: 'Subscription',
    },
    { path: 'fees', component: Fees, pageTitle: 'Fees' },
    {
        path: 'communication-preferences',
        component: CommunicationPreferences,
        pageTitle: 'Communication Preferences',
    },
    { path: 'settings', component: Settings, pageTitle: 'Settings' },
    {
        path: 'change-password',
        component: ChangePassword,
        pageTitle: 'Change Password',
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditions,
        pageTitle: 'Terms and Conditions',
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicy,
        pageTitle: 'Privacy Policy',
    },
    {
        path: 'refer-a-friend',
        component: ReferAFriend,
        pageTitle: 'Refer a Friend',
    },
    {
        path: 'cashback-rewards',
        component: CashbackRewards,
        pageTitle: 'Cashback Rewards',
    },
];
