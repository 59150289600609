import React from 'react';

import LanguageSelect from 'components/shared/form/presentational/LanguageSelect';

import Logo from '_content/images/logo.svg';
import useSelectLanguage from '../_hooks/useSelectLanguage';

const Header = () => {
    const [languageCode, handleChange] = useSelectLanguage();

    return (
        <header>
            <img src={Logo} alt="Logo" />

            <LanguageSelect
                name="languageCode"
                onChange={handleChange}
                value={languageCode}
                required
            />
        </header>
    );
};

export default Header;
