import React from 'react';
import { formatMoney } from 'suits-me-npm/utils';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import BulletPoint from 'components/shared/generic/bulletPoint/presentational/BulletPoint';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const Fees = ({ cardFee }) => {
    const translate = useTranslate('fees');
    return (
        <div className="fees-container">
            <div className="fees-section">
                <SectionTitle title={translate('cardFees')} secondary />
                <BulletPoint
                    leftText={translate('additionalCardText')}
                    rightText={`£${formatMoney(cardFee)}`}
                />
                <BulletPoint
                    leftText={translate('replacementCardText')}
                    rightText={`£${formatMoney(cardFee)}`}
                />
            </div>
        </div>
    );
};

export default Fees;
