import React from 'react';
import InternationalQuoteCalculatorContainer from 'components/shared/generic/internationalQuoteCalculator/containers/InternationalQuoteCalculatorContainer';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const InitialQuote = ({ continueClick }) => {
    const translate = useTranslate('internationalPayment')
    return (
        <InternationalQuoteCalculatorContainer
            buttonText={translate('proceed')}
            temporary
            onContinueClick={continueClick}
        />
    );
};

export default InitialQuote;
