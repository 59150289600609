import { useState, useEffect } from 'react';
import {
    sliceCarouselCards,
    changeDirection,
    shouldChange,
    addCardholder,
} from 'utils/cards';

export default cards => {
    const [carouselCards, setCarouselCards] = useState([]);
    const [currentCard, setCurrentCard] = useState({});
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handleDirection = clockwise =>
        setCurrentCardIndex(changeDirection(currentCardIndex, clockwise));

    useEffect(() => {
        addCardholder(cards);
        //eslint-disable-next-line
    }, [cards]);

    useEffect(() => {
        setCarouselCards(sliceCarouselCards(cards, currentCardIndex));
        setCurrentCard(cards[currentCardIndex]);
        setIsStart(shouldChange(cards, currentCardIndex, false));
        setIsEnd(shouldChange(cards, currentCardIndex, true));
        //eslint-disable-next-line
    }, [currentCardIndex, cards]);

    return {
        carouselCards,
        currentCard,
        currentCardIndex,
        handleDirection,
        isStart,
        isEnd,
    };
};
