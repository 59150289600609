import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'actions/modal';
import ModalOuter from '../presentational/ModalOuter';

const ModalOuterContainer = props => {
    const {
        children,
        hideModal,
        className,
        handleSubmit,
        isPosting,
        customButtons = false,
    } = props;

    return (
        <ModalOuter
            className={`modal-outer ${className}`}
            handleClose={hideModal}
            handleSubmit={handleSubmit}
            customButtons={customButtons}
            isPosting={isPosting}
        >
            {children}
        </ModalOuter>
    );
};

const mapDispatchToProps = { hideModal };

export default connect(null, mapDispatchToProps)(ModalOuterContainer);
