import { useCallback, useEffect } from 'react';

export default (callback, loading) => {
    const loadMore = useCallback(
        ([entry]) => {
            if (entry.isIntersecting && !loading) {
                callback();
            }
        },
        [callback],
    );

    const observer = new IntersectionObserver(loadMore, {
        root: null,
        rootMargin: '0px',
        threshold: 0.25,
    });

    const ref = useCallback(loadMoreRef => {
        if (loadMoreRef) {
            observer.observe(loadMoreRef);
        }
    }, []);

    useEffect(() => {
        return () => {
            observer.disconnect();
        };
    }, []);

    return ref;
};
