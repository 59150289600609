import ReviewQuote from 'components/activity/payments/international/reviewQuote/containers/ReviewQuoteContainer';
import SelectRecipient from 'components/activity/payments/international/selectRecipient/main/containers/SelectRecipientContainer';
import AddNewRecipient from 'components/activity/payments/international/selectRecipient/addNewRecipient/containers/AddNewRecipientContainer';
import MoreDetails from 'components/activity/payments/international/moreDetails/containers/MoreDetailsContainer';
import ReviewAndSend from 'components/activity/payments/international/review/containers/ReviewAndSendContainer';
import PaymentStatus from 'components/activity/payments/international/success/containers/PaymentSuccessContainer';
import CheckInternationalUser from 'components/activity/payments/international/checkUser/containers/CheckInternationalUserContainer';
import InitialQuote from 'components/activity/payments/international/initialQuote/containers/InitialQuoteContainer';

export default [
    {
        path: 'initial-quote',
        component: InitialQuote,
        pageTitle: 'Initial Quote | Transferwise',
    },
    {
        path: 'check',
        component: CheckInternationalUser,
        pageTitle: 'Account | Transferwise',
    },
    {
        path: 'review-quote',
        component: ReviewQuote,
        pageTitle: 'Review Quote | Transferwise',
    },
    {
        path: 'select-recipient',
        component: SelectRecipient,
        pageTitle: 'Select Recipient | Transferwise',
    },
    {
        path: 'select-recipient/add',
        component: AddNewRecipient,
        pageTitle: 'Add Recipient | Transferwise',
    },
    {
        path: 'more-details',
        component: MoreDetails,
        pageTitle: 'More Details | Transferwise',
    },
    {
        path: 'review',
        component: ReviewAndSend,
        pageTitle: 'Review | Transferwise',
    },
    {
        path: 'status',
        component: PaymentStatus,
        pageTitle: 'Status | Transferwise',
    },
];
