import React from 'react';

const Right = ({ className = '', onClick, disabled }) => (
    <span
        className={`arrow-button right ${className} ${disabled ? 'disabled' : ''}`}
        onClick={onClick}
    >
        <i className="caret fas fa-caret-right" />
    </span>
);

export default Right;
