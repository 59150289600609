import React from 'react';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TransferWiseError = () => {
    const translate = useTranslate('internationalPayment')
    return (
        <div className="transferwise-error">
            <h1>{translate('error.error')}</h1>
            <p>{translate('error.connect')}</p>
        </div>
    );
};

export default TransferWiseError;
