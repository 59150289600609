import React from 'react';
import Accordion from 'components/shared/generic/accordion/presentational/Accordion';
import BulletPoint from 'components/shared/generic/bulletPoint/presentational/BulletPoint';

const ZendeskFAQs = ({ faqs }) => {
    return (
        <div className="zendesk-faqs">
            {faqs.map(({ title, body }, index) => {
                const formattedBody = body
                    .replace(/<\/?span[^>]*>/g, '')
                    .replace(/<p><a([^<]*)<\/a><\/p>/gi, '')
                    .replace(/(<([^>]+)>)/gi, '')
                    .split('\n')
                    .filter(text => text.trim())
                    .map((text, i) => (
                        <BulletPoint key={i} small leftText={text} />
                    ));

                return (
                    <Accordion key={index} title={title} tracking>
                        {formattedBody}
                    </Accordion>
                );
            })}
        </div>
    );
};

export default ZendeskFAQs;
