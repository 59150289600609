export const PopularCurrienciesList = [
    {
        name: 'British Pound',
        flag: '',
        currency_code: 'GBP',
    },
    {
        name: 'European Euro',
        flag: '',
        currency_code: 'EUR',
    },
    {
        name: 'U.S Dollar',
        flag: '',
        currency_code: 'USD',
    },
];

export const AllCurrienciesList = [
    {
        name: 'Australian Dollar',
        flag: '',
        currency_code: 'AUD',
    },
    {
        name: 'Indian Rupee',
        flag: '',
        currency_code: 'INR',
    },
    {
        name: 'Canadian Dollar',
        flag: '',
        currency_code: 'CAD',
    },
    {
        name: 'British Pound',
        flag: '',
        currency_code: 'GBP',
    },
    {
        name: 'European Euro',
        flag: '',
        currency_code: 'EUR',
    },
    {
        name: 'U.S Dollar',
        flag: '',
        currency_code: 'USD',
    },
];
