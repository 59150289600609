import React from 'react';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import InfiniteScroll from 'components/shared/infiniteScroll/presentational/InfiniteScroll';

const ManagePayees = ({ data, last, handleDelete, handleFetchPage }) => {
    const ref = useInfiniteScroll(handleFetchPage);

    return (
        <div className="uk-payee-container">
            <InfiniteScroll
                className="uk-payee-list-container"
                ref={ref}
                last={last}
            >
                {Object.values(data).map((payee, index) => {
                    return (
                        <div key={index} className="uk-payee-item">
                            <div className="item-left">
                                <h1 className="uk-payee-name">
                                    {payee.payeeName}
                                </h1>
                                <div className="uk-payee-account">
                                    <p>{payee.accountNo}</p>
                                    <span>|</span>
                                    <p>{payee.sortCode}</p>
                                </div>
                            </div>
                            <button
                                className="delete-button"
                                onClick={() => handleDelete(payee)}
                            >
                                <i className="fas fa-trash-alt" />
                            </button>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default ManagePayees;
