import React from 'react';
import { PASSWORD_REGEXP } from 'suits-me-npm/constants';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import TextInput from 'components/shared/form/presentational/TextInput';
import PasswordMeter from 'components/shared/form/presentational/PasswordMeter';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const Password = ({
    password,
    confirmPassword,
    handleChange,
    handleSubmit,
    validatePassword,
    validateConfirmPassword,
    isPosting,
    error,
    hidePasswordTitle,
}) => {
    const translate = useTranslate('setPassword');

    return (
        <Box className="password-container">
            {!hidePasswordTitle && (
                <div className="statement-container">
                    <SectionTitle title={translate('titleText')} secondary />
                    <div className="statement mb">
                        <Typography>{translate('descriptionText')}</Typography>
                    </div>
                </div>
            )}
            <Form
                className="password-form"
                submitText={translate('form.confirm')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? `${translate('form.errorMessage')}` : ''}
            >
                <Field required>
                    <TextInput
                        required
                        placeholder={translate('form.password')}
                        onChange={handleChange}
                        name="password"
                        value={password}
                        validate={validatePassword}
                        type="password"
                        classes="generic-input"
                        minLength={10}
                        validationRegexp={PASSWORD_REGEXP}
                    />
                </Field>

                <Field required>
                    <TextInput
                        required
                        placeholder={translate('form.confirmPassword')}
                        onChange={handleChange}
                        name="confirmPassword"
                        validate={validateConfirmPassword}
                        value={confirmPassword}
                        type="password"
                        classes="generic-input"
                        minLength={10}
                    />
                </Field>
                <PasswordMeter password={password} />
            </Form>
        </Box>
    );
};

export default Password;
