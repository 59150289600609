import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CodeInput from 'components/shared/form/presentational/CodeInput';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ValidatePasscode = ({
    description,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    const translate = useTranslate('validatePasscode');
    return (
        <Box className="enter-passcode-container">
            <div className="statement-container">
                <SectionTitle title={translate('titleText')} secondary />
                <div className="statement mb">
                    <Typography>
                        {translate('description', { description })}
                    </Typography>
                </div>
            </div>
            <Form
                className="enter-passcode-form"
                submitText={translate('submitText')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? translate('errorMessage') : ''}
            >
                <Field required>
                    <CodeInput
                        fields={2}
                        width={38}
                        height={52}
                        name="values"
                        onChange={handleChange}
                    />
                </Field>
            </Form>
        </Box>
    );
};

export default ValidatePasscode;
