import React from 'react';
import dayjs from 'dayjs';
import TransactionCard from './TransactionsCard';

const Transactions = ({ data }) => {
    return (
        <>
            {Object.keys(data)
                .sort((a, b) => b.localeCompare(a))
                .map((date, index) => {
                    const formattedDate = dayjs(date);
                    const transactions = data[date];

                    return (
                        <div
                            key={index}
                            className="monthly-transaction-container"
                        >
                            <h2>{formattedDate.format('MMMM DD, YYYY')}</h2>
                            {transactions.map((item, index) => {
                                return (
                                    <TransactionCard
                                        key={index}
                                        item={item}
                                        index={index}
                                        keyIndex={`${dayjs(
                                            item.createdDate,
                                        ).format()}-${index}`}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
        </>
    );
};

export default Transactions;
