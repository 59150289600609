import React from 'react'

import { twUseAddRecipientField } from 'suits-me-npm/hooks'

import DatePicker from 'components/shared/form/presentational/DatePicker'
import Field from 'components/shared/form/presentational/Field'
import Radio from 'components/shared/form/presentational/Radio'
import Select from 'components/shared/form/presentational/Select'
import TextInput from 'components/shared/form/presentational/TextInput'

const fieldTypes = {
    select: Select,
    text: TextInput,
    radio: Radio,
    date: DatePicker,
}

const AddRecipientFieldRoute = ({
    field: { key, name, required, validationRegexp, type },
    field,
}) => {
    const [value, handleChange, options] = twUseAddRecipientField(field)
    const SpecifiedField = fieldTypes[type]

    return (
        <Field name={type === 'radio' ? name : ''}>
            <SpecifiedField
                classes="generic-input transparent"
                required={required}
                placeholder={name}
                name={key}
                options={options}
                onChange={handleChange}
                value={value}
                validationRegexp={validationRegexp}
            />
        </Field>
    )
}

export default AddRecipientFieldRoute
