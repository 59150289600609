import React from 'react';
import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import ManageSubscription from '../presentational/ManageSubscription';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ManageSubscriptionContainer = () => {
    const translate = useTranslate('pricePlan');

    return (
        <PageContainer className="more-manage-subscription-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={MORE}
            />
            <ManageSubscription />
        </PageContainer>
    );
};

export default ManageSubscriptionContainer;
