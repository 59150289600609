import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchActivity } from 'suits-me-npm/actions';

const useTransactions = () => {
    const dispatch = useDispatch();

    const fetchNextPage = useCallback(() => {
        dispatch(fetchActivity());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchActivity(true));
    }, []);

    return {
        fetchNextPage,
    };
};

export default useTransactions;
