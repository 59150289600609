import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRedirectRoute } from 'selectors/redirect';
import { clearRedirectRoute } from 'actions/redirect';

const GlobalRedirect = ({ redirectRoute }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const clear = useCallback(() => dispatch(clearRedirectRoute()), [dispatch]);

    useEffect(() => {
        if (redirectRoute) {
            history.push(redirectRoute);
            clear();
        }
    }, [redirectRoute, clear, history]);

    return <></>;
};

const mapState = state => ({
    redirectRoute: getRedirectRoute(state),
});

export default connect(mapState)(GlobalRedirect);
