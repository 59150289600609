import React from 'react';
import { SUITS_ME_APPLY, SUITS_ME_VIEW_GUIDE } from 'constants/shared/routes';
import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import FieldLink from 'components/shared/form/presentational/FieldLink';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import DatePicker from 'components/shared/form/presentational/DatePicker';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const Login = ({
    username,
    password,
    dob,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    const translate = useTranslate('loginWeb.form');
    return (
        <Box className="login-container">
            <Form
                className="login-form"
                submitText={translate('submitText')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? translate('errorMessage') : ''}
            >
                <Field required>
                    <TextInput
                        required
                        placeholder={translate('username')}
                        onChange={handleChange}
                        name="username"
                        value={username}
                        classes="generic-input"
                    />
                </Field>

                <Field required>
                    <TextInput
                        required
                        placeholder={translate('password')}
                        onChange={handleChange}
                        name="password"
                        value={password}
                        type="password"
                        classes="generic-input"
                    />
                    <FieldLink to="forgotten-password">
                        {translate('forgotPassword')}
                    </FieldLink>
                </Field>

                <Field required>
                    <DatePicker
                        required
                        placeholderText={translate('dob')}
                        onChange={handleChange}
                        name="dob"
                        value={dob}
                        classes="generic-input"
                        omitClear
                    />
                </Field>
            </Form>
            <FieldLink tracking href={SUITS_ME_APPLY}>
                {translate('apply')}
            </FieldLink>
            <div className="statement">
                <Typography>{translate('statement')}</Typography>
                <Typography>
                    <a target="blank" href={SUITS_ME_VIEW_GUIDE}>
                        {translate('linkText')}
                    </a>
                </Typography>
            </div>
        </Box>
    );
};
export default Login;
