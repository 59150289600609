export default [
    {
        name: 'United Kingdom',
        code: 'UK',
    },
    {
        name: 'Austria',
        code: 'AT',
    },
    {
        name: 'Belgium',
        code: 'BE',
    },
    {
        name: 'Cyprus',
        code: 'CY',
    },
    {
        name: 'Estonia',
        code: 'EE',
    },
    {
        name: 'Finland',
        code: 'FI',
    },
    {
        name: 'France',
        code: 'FR',
    },
    {
        name: 'Germany',
        code: 'DE',
    },
    {
        name: 'Greece',
        code: 'EL',
    },
    {
        name: 'Ireland',
        code: 'IE',
    },
    {
        name: 'Italy',
        code: 'IT',
    },
    {
        name: 'Latvia',
        code: 'LV',
    },
    {
        name: 'Lithuania',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
    },
    {
        name: 'Malta',
        code: 'MT',
    },
    {
        name: 'Netherlands',
        code: 'NL',
    },
    {
        name: 'Portugal',
        code: 'PT',
    },
    {
        name: 'Slovakia',
        code: 'SK',
    },
    {
        name: 'Slovenia',
        code: 'SI',
    },
    {
        name: 'Spain',
        code: 'ES',
    },
];
