import React from 'react';

import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import PostCodeAutoComplete from 'components/shared/form/presentational/PostCodeAutoComplete';
import { getCountryCode } from 'utils/generic';
// import Switch from 'components/shared/generic/switch/presentational/Switch'; <-- For primary tax

const EditPersonalDetails = ({
    handleChange,
    handleSubmit,
    isPosting,
    error,
    // showPrimaryTaxChange, <-- For primary tax
    // handleSwitchClick, <-- For primary tax
    ...props
}) => {
    const translate = useTranslate('personalDetails.form');
    const {
        email,
        tel1,
        tel2,
        addressLine1,
        addressLine2,
        town,
        postcode,
        country,
        // primaryTaxAddess, <-- For primary tax
    } = props;

    return (
        <>
            <Form
                submitText={translate('save')}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? translate('errorMessage') : ''}
                tracking
                trackingLabel="EDIT_DETAILS_SUBMIT"
            >
                <Field name={translate('email')} required>
                    <TextInput
                        name="email"
                        placeholder={translate('email')}
                        value={email}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field required>
                    <PostCodeAutoComplete
                        value={postcode}
                        onChange={handleChange}
                        classes="generic-input bg mb"
                        countryCode={getCountryCode(country)}
                    />
                </Field>
                <Field name={translate('addressLine1')} required>
                    <TextInput
                        name="addressLine1"
                        placeholder={translate('addressLine1')}
                        value={addressLine1}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('addressLine2')}>
                    <TextInput
                        name="addressLine2"
                        placeholder={translate('addressLine2')}
                        value={addressLine2}
                        onChange={handleChange}
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('townCity')} required>
                    <TextInput
                        name="town"
                        placeholder={translate('townCity')}
                        value={town}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('country')} required>
                    <TextInput
                        name="country"
                        placeholder={translate('country')}
                        value={country}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                        disabled
                    />
                </Field>
                <Field name={translate('postcode')} required>
                    <TextInput
                        name="postcode"
                        placeholder={translate('postcode')}
                        value={postcode}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                        disabled
                    />
                </Field>
                <Field name={translate('telephone1')} required>
                    <TextInput
                        name="tel1"
                        placeholder={translate('telephone1')}
                        value={tel1}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('telephone2')}>
                    <TextInput
                        name="tel2"
                        placeholder={translate('telephone2')}
                        value={tel2}
                        onChange={handleChange}
                        classes="generic-input bg"
                    />
                </Field>
                {/* {showPrimaryTaxChange && (
                    <Field>
                        <Switch
                            name="primaryTaxAddess"
                            value={primaryTaxAddess}
                            onChange={handleSwitchClick}
                        >
                            <label className="title">
                                {translate('setPrimaryTaxResidency')}
                            </label>
                        </Switch>
                    </Field>
                )} <-- For primary tax */}
            </Form>
        </>
    );
};
export default EditPersonalDetails;
