import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

const PrependTextInput = ({
    type = 'text',
    name,
    placeholder,
    classes,
    value,
    onChange,
    charLimit,
    maxNum,
    disabled,
    showError,
    prependText = '£',
}) => (
    <div className="input-group">
        <div className="input-group-prepend">
            <span className="input-group-text">{prependText}</span>
        </div>
        <input
            className={classes}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={newVal => {
                onChange(name, newVal.target.value);
            }}
            onBlur={showError}
            maxLength={charLimit}
            max={maxNum}
            disabled={disabled}
        />
    </div>
);

export default withFieldValidation(PrependTextInput);
