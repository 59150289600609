import React from 'react';

import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const PasswordRecovery = ({
    form,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => (
    <Box className="security-questions-container">
        <div className="statement-container">
            <SectionTitle title="Set Security Questions" secondary />
            <div className="statement mb">
                <Typography>
                    Please create 3 security questions for privacy purposes.
                </Typography>
            </div>
        </div>
        <Form
            className="security-questions-form"
            submitText="Confirm"
            buttonSize="full"
            onSubmit={handleSubmit}
            isPosting={isPosting}
            error={error ? 'A problem occurred! Please try again.' : ''}
        >
            {[1, 2, 3].map((item, index) => {
                return (
                    <Box key={index}>
                        <Typography className="question-title">
                            Question {item}
                        </Typography>
                        <Field required>
                            <TextInput
                                required
                                placeholder="Create question"
                                onChange={handleChange}
                                name={`question${item}`}
                                value={form[`question${item}`]}
                                classes="generic-input"
                                appendIcon="?"
                            />
                        </Field>
                        <Field required>
                            <TextInput
                                required
                                placeholder="Answer"
                                onChange={handleChange}
                                name={`answer${item}`}
                                value={form[`answer${item}`]}
                                classes="generic-input"
                            />
                        </Field>
                    </Box>
                );
            })}
        </Form>
    </Box>
);

export default PasswordRecovery;
