import LoginContainer from 'components/auth/login/containers/LoginContainer';
import ActivationContainer from 'components/auth/activation/containers/ActivationContainer';
import SetPasscodeContainer from 'components/auth/setPasscode/containers/SetPasscodeContainer';
import PasswordContainer from 'components/auth/password/containers/PasswordContainer';
import SecurityQuestionsContainer from 'components/auth/securityQuestions/containers/SecurityQuestionsContainer';
import ValidatePasscodeContainer from 'components/auth/enterPasscode/containers/ValidatePasscodeContainer';
import LoadingContainer from 'components/auth/loading/containers/LoadingContainer';

export const activeLoginMap = {
    0: LoginContainer,
    10: ActivationContainer,
    20: PasswordContainer,
    30: SetPasscodeContainer,
    40: SecurityQuestionsContainer,
    50: ValidatePasscodeContainer,
    60: LoadingContainer,
};
