import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import CurrencyInput from 'components/shared/form/presentational/CurrencyInput';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const MakePaymentForm = ({
    handleChange,
    handleSubmit,
    isPosting,
    ...props
}) => {
    const translate = useTranslate('makePayment');
    const isSubmitDisabled =
        !props.Amount.length ||
        props.Amount === '£0.00' ||
        !props.PaymentReference.length;

    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            disabledButton={isSubmitDisabled}
        >
            <Field required>
                <CurrencyInput
                    name="Amount"
                    placeholder={translate('form.Amount')}
                    onChange={handleChange}
                    value={props.Amount}
                    classes="generic-input bg"
                    prefix="£"
                />
            </Field>
            <Field required>
                <TextInput
                    name="PaymentReference"
                    placeholder={translate('form.PaymentReference')}
                    onChange={handleChange}
                    value={props.PaymentReference}
                    classes="generic-input bg"
                />
            </Field>
        </Form>
    );
};

export default MakePaymentForm;
