import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmAndSendModal from '../presentational/ConfirmAndSendModal';

const ConfirmAndSendModalContainer = ({
    selectedPayee,
    form,
    handleSubmit,
    error,
    hideModal,
    postingSelector,
    type,
}) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(postingSelector);

    const handleClose = () => dispatch(hideModal);

    return (
        <ConfirmAndSendModal
            {...form}
            selectedPayee={selectedPayee}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            isError={error}
            type={type}
        />
    );
};

export default ConfirmAndSendModalContainer;
