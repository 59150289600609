import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import TextInput from 'components/shared/form/presentational/TextInput';

const Activation = ({
    activationCode,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => (
    <Box className="activation-container">
        <div className="statement-container">
            <SectionTitle title="Activation Code" secondary />
            <div className="statement mb">
                <Typography>
                    Please enter the activation code you received from Suits Me.
                </Typography>
            </div>
        </div>
        <Form
            // className="activation-form"
            submitText="Activate"
            buttonSize="full"
            onSubmit={handleSubmit}
            isPosting={isPosting}
            error={error ? 'Something went wrong! Please try again.' : ''}
        >
            <Field required>
                <TextInput
                    name="activationCode"
                    value={activationCode}
                    onChange={handleChange}
                    required
                    placeholder="Activation code"
                    classes="generic-input"
                />
            </Field>
        </Form>
    </Box>
);

export default Activation;
