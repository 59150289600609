import { accountRoles } from 'suits-me-npm/constants';

import essentialCard from '_content/images/cards/essential_card.png';
import premiumCard from '_content/images/cards/premium_card.png';
import premiumPlusCard from '_content/images/cards/premium_plus_card.png';

export const passwordLabel = {
    0: 'Weak',
    1: 'Weak',
    2: 'Fair',
    3: 'Good',
    4: 'Strong',
};

export const cardPos = {
    0: 'left',
    1: 'center',
    2: 'right',
};

export const cardImages = {
    [accountRoles.Essential]: essentialCard,
    [accountRoles.Premium]: premiumCard,
    [accountRoles.PremiumPlus]: premiumPlusCard,
    [accountRoles.EssentialEuro]: essentialCard,
    [accountRoles.PremiumEuro]: premiumCard,
    [accountRoles.PremiumPlusEuro]: premiumPlusCard,
};

export const TransactionStatus = {
    incoming_payment_waiting: 'Pending',
};

export const StatementType = {
    CSV: 1,
    PDF: 2,
};
