import React from 'react';

import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import PrependTextInput from 'components/shared/form/presentational/PrependTextInput';
import Form from 'components/shared/form/presentational/Form';

const SendPayment = ({
    isPosting,
    error,
    recipientName,
    amount,
    recipientAccountNumber,
    recipientSortCode,
    reference,
    handleChange,
    handleSubmit,
    handleBack,
}) => (
    <div className="send-payments-container">
        <h1 className="section-title">Send Payments</h1>
        <Form
            submitText="Save Changes"
            onSubmit={handleSubmit}
            onCancel={handleBack}
            isPosting={isPosting}
            error={error ? 'Something went wrong! Please try again.' : ''}
        >
            <Field required>
                <TextInput
                    required
                    placeholder="Recipient Name"
                    onChange={handleChange}
                    name="recipientName"
                    value={recipientName}
                    classes="generic-input bg"
                />
            </Field>

            <Field required>
                <PrependTextInput
                    required
                    placeholder="Amount"
                    onChange={handleChange}
                    name="amount"
                    value={amount}
                    classes="generic-input bg"
                    prependText="£"
                />
            </Field>

            <Field required>
                <TextInput
                    required
                    placeholder="Account Number"
                    onChange={handleChange}
                    name="recipientAccountNumber"
                    value={recipientAccountNumber}
                    classes="generic-input bg"
                />
            </Field>

            <Field required>
                <TextInput
                    required
                    placeholder="Sort Code"
                    onChange={handleChange}
                    name="recipientSortCode"
                    value={recipientSortCode}
                    classes="generic-input bg"
                />
            </Field>

            <Field required>
                <TextInput
                    required
                    placeholder="Reference"
                    onChange={handleChange}
                    name="reference"
                    value={reference}
                    classes="generic-input bg"
                />
            </Field>
        </Form>
    </div>
);

export default SendPayment;
