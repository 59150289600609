import React from 'react';
import Box from 'components/shared/generic/box/presentational/Box';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const Loading = () => (
    <Box className="loading-container">
        <div className="loading">
            <i className="far fa-spinner fa-spin" />
            <Typography>Redirecting to dashboard</Typography>
        </div>
    </Box>
);

export default Loading;
