export const sliceCarouselCards = (cards, index) => {
    if (!cards) return cards;

    const prevItem = cards[index - 1];
    const currentItem = cards[index];
    const nextItem = cards[index + 1];

    return [prevItem, currentItem, nextItem];
};

export const addCardholder = cards => {
    if (!cards || cards.length > 1) return;
    cards.push({ status: 'N' });
    return cards;
};

export const changeDirection = (index, clockwise) =>
    clockwise ? index + 1 : index - 1;

export const shouldChange = (cards, index, clockwise) => {
    const newIndex = clockwise ? index + 1 : index - 1;

    return !cards[newIndex];
};

export const maskedCardSerial = cardSerial => {
    if (!cardSerial) return null;

    const maskedNumber = cardSerial.replace(/.(?=.{4})/g, '*');

    return maskedNumber.replace(/(.{6})/g, '$1 ');
};

export const formattedCardSerial = cardSerial => {
    if (!cardSerial) return null;

    return cardSerial.replace(/(\d{6})(\d{4})/, '$1 $2');
};

export const maskedCardCVV = cardCVV => {
    if (!cardCVV) return null;

    const maskedNumber = cardCVV.replace(/.(?=.{4})/g, '*');

    return maskedNumber.replace(/(.{6})/g, '$1 ');
};

export const formattedCardCVV = cardCVV => {
    if (!cardCVV) return null;

    return cardCVV.replace(/(\d{6})(\d{4})/, '$1 $2');
};
