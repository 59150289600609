import { createReducer } from '@reduxjs/toolkit';
import { setTargetCurrency } from 'actions/tw-quote';

const initialState = {
    targetCurrency: 'USD',
};

const handleSetTargetCurrency = (state, action) => {
    state.targetCurrency = action.payload;
};

export default createReducer(initialState, {
    [setTargetCurrency]: (state, action) => handleSetTargetCurrency(state, action),
});
