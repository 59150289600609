import React from 'react';
import { EMAIL_REGEXP, FULL_NAME_REGEXP } from 'suits-me-npm/constants/regexp';

import Field from 'components/shared/form/presentational/Field';
import TextInput from 'components/shared/form/presentational/TextInput';
import TabSelect from 'components/shared/form/presentational/TabSelect';
import AddRecipientFieldRoute from './AddRecipientFieldRoute';
import Form from 'components/shared/form/presentational/Form';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const AddNewRecipient = ({
    handleChange,
    handleSubmit,
    typeOptions,
    usageInfo,
    fields,
    accountHolderName,
    email,
    type,
    isPosting,
    error,
    data,
}) => {
    const emailRequired = type === 'email';
    const translate = useTranslate('internationalPayment');

    return (
        <div className="add-international-recipient-container">
            <Form
                submitText={translate('addNewRecipient.submit')}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={!data && error ? translate('addNewRecipient.error') : ''}
                tracking
                trackingLabel="TRANSFERWISE_ADD_NEW_RECIPIENT_FORM"
            >
                <Field required>
                    <TextInput
                        classes="generic-input"
                        required
                        placeholder={translate('addNewRecipient.form.name')}
                        onChange={handleChange}
                        name="accountHolderName"
                        value={accountHolderName}
                        validationRegexp={FULL_NAME_REGEXP}
                        tooltip="Example: Mark Baker"
                    />
                </Field>
                <Field required={emailRequired}>
                    <TextInput
                        classes="generic-input"
                        required={emailRequired}
                        placeholder={`${translate(
                            'addNewRecipient.form.email',
                        )} ${
                            emailRequired
                                ? ''
                                : `${translate(
                                      'addNewRecipient.form.optional',
                                  )}`
                        }`}
                        onChange={handleChange}
                        name="email"
                        value={email}
                        validationRegexp={EMAIL_REGEXP}
                    />
                </Field>
                <TabSelect
                    name="type"
                    value={type}
                    onChange={handleChange}
                    options={typeOptions}
                />
                {!!usageInfo && <p className="usage-info">{usageInfo}</p>}
                {fields.map(({ group }) => {
                    return group.map(field => (
                        <AddRecipientFieldRoute key={field.key} field={field} />
                    ));
                })}
            </Form>
        </div>
    );
};

export default AddNewRecipient;
