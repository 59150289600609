import React, { useState } from 'react';
import { PASSWORD_REGEXP } from 'suits-me-npm/constants';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import TextInput from 'components/shared/form/presentational/TextInput';
import PasswordMeter from 'components/shared/form/presentational/PasswordMeter';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ChangePassword = ({
    newPassword,
    confirmPassword,
    handleChange,
    handleSubmit,
    oldPassword,
    isPosting,
    error,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const translate = useTranslate('personalDetails.changePasswordForm');

    return (
        <div className="change-password-container">
            <Form
                className="password-form"
                submitText={translate('confirm')}
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? translate('error') : ''}
            >
                <Field>
                    <TextInput
                        required
                        placeholder={translate('oldPassword')}
                        onChange={handleChange}
                        name="oldPassword"
                        value={oldPassword}
                        type="password"
                        classes="generic-input"
                        minLength={10}
                        validationRegexp={PASSWORD_REGEXP}
                    />
                </Field>
                <Field required>
                    <TextInput
                        required
                        placeholder={translate('newPassword')}
                        onChange={handleChange}
                        name="newPassword"
                        value={newPassword}
                        type={showPassword ? 'text' : 'password'}
                        classes="generic-input"
                        minLength={10}
                        validationRegexp={PASSWORD_REGEXP}
                        appendIcon={
                            <i
                                className="fa fa-eye"
                                onClick={() => setShowPassword(state => !state)}
                            />
                        }
                    />
                </Field>

                <Field required>
                    <TextInput
                        required
                        placeholder={translate('confirmPassword')}
                        onChange={handleChange}
                        name="confirmPassword"
                        value={confirmPassword}
                        type={showConfirmPassword ? 'text' : 'password'}
                        classes="generic-input"
                        minLength={10}
                        appendIcon={
                            <i
                                className="fa fa-eye"
                                onClick={() =>
                                    setShowConfirmPassword(state => !state)
                                }
                            />
                        }
                    />
                </Field>
                <PasswordMeter password={newPassword} />
            </Form>
        </div>
    );
};

export default ChangePassword;
