import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-XXXXXXXXX-X';

const init = () => {
    ReactGA.initialize(TRACKING_ID);
};

const sendEvent = (category, action, label, value) => {
    ReactGA.event({ category, action, label, value });
};

const sendFormEvent = (action, label) => {
    ReactGA.event({ category: 'FORM', action, label: `APP_${label}` });
};

const sendButtonEvent = (action, label) => {
    ReactGA.event({ category: 'BUTTON', action, label: `APP_${label}` });
};

const sendLinkEvent = (action, label) => {
    ReactGA.event({ category: 'LINK', action, label: `APP_${label}` });
};

const sendCTAEvent = (action, label) => {
    ReactGA.event({ category: 'CTA', action, label });
};

const sendPageview = path => {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
};

export default {
    init,
    sendEvent,
    sendFormEvent,
    sendButtonEvent,
    sendLinkEvent,
    sendCTAEvent,
    sendPageview,
};
