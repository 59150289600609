import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getTwQuote } from 'suits-me-npm/selectors';
import { isEmpty } from 'suits-me-npm/utils';
import { PAYMENT_MAKE_PAYMENT } from 'constants/shared/routes';

export default (path = PAYMENT_MAKE_PAYMENT) => {
    const history = useHistory();
    const quote = useSelector(getTwQuote)

    const handleNoQuoteRedirect = () =>
        history.push({ pathname: path, state: { international: true } });

    useEffect(() => {
        if(isEmpty(quote)) {
            handleNoQuoteRedirect()
        }
        // eslint-disable-next-line
    }, []);
};
