import React from 'react';
import { useDispatch } from 'react-redux';
import { createSecurityQuestions } from 'suits-me-npm/actions';
import { useForm } from 'suits-me-npm/utils';
import SecurityQuestions from '../presentational/SecurityQuestions';

const SecurityQuestionsContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({
        question1: '',
        answer1: '',
        question2: '',
        answer2: '',
        question3: '',
        answer3: '',
    });

    const handleSubmit = () => {
        dispatch(createSecurityQuestions(form));
    };

    return (
        <SecurityQuestions
            form={form}
            isPosting={isPosting}
            error={error}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
};

export default SecurityQuestionsContainer;
