import React from 'react';
import { useSelector } from 'react-redux';
import { getAccount, getAccountIsFetching } from 'suits-me-npm/selectors';
import { newCardFees } from 'suits-me-npm/constants/fees';
import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Fees from '../presentational/Fees';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const FeesContainer = () => {
    const translate = useTranslate('fees');

    const { productType } = useSelector(getAccount);
    const isFetching = useSelector(getAccountIsFetching);
    const replacementCardFee = newCardFees[productType];

    return (
        <PageContainer className="more-fees-container">
            <SectionTitle
                title={translate('breadcrumbText')}
                backRoute={MORE}
            />
            <DataCheck
                data={productType}
                isLoading={isFetching}
                noDataMessage={translate('noDataMessage')}
            >
                <Fees cardFee={replacementCardFee} />
            </DataCheck>
        </PageContainer>
    );
};

export default FeesContainer;
