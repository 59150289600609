import React from 'react';

import ModalOuterContainer from 'components/shared/modals/generic/containers/ModalOuterContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ExcessiveFundsModal = ({ hideModal }) => {
    const translate = useTranslate('pricePlan');
    return (
        <ModalOuterContainer customButtons className="status-modal">
            <div className="description">
                <Typography tag="h1">
                    {translate('excessiveFundsModal.titleText')}
                </Typography>
                <Typography className="generic-text intro-text">
                    {translate('excessiveFundsModal.description')}
                </Typography>
            </div>
            <ButtonContainer>
                <Button onClick={hideModal}>
                    {translate('excessiveFundsModal.close')}
                </Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default ExcessiveFundsModal;
