import React from 'react';
import { activeLoginMap } from 'constants/shared/auth';
import useLoginSteps from 'utils/hooks/useLoginSteps';
import Auth from '../presentational/Auth';

const AuthContainer = () => {
    const { loginStep, isPosting, error } = useLoginSteps();

    const ActiveComponent = activeLoginMap[loginStep || 0];

    return (
        <Auth>
            <ActiveComponent isPosting={isPosting} error={error} />
        </Auth>
    );
};

export default AuthContainer;
