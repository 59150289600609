import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getTransactionsList,
    getTransactionsIsFetching,
    getTransactionsFetchError,
    getIsLastPage,
} from 'suits-me-npm/selectors';
import useInfiniteTransactionScroll from 'utils/hooks/useInfiniteTransactionScroll';
import { formattedTransactions } from 'suits-me-npm/utils';
import AdvertsContainer from '../../adverts/containers/AdvertsContainer';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import InfiniteScroll from 'components/shared/infiniteScroll/presentational/InfiniteScroll';
import Transactions from '../presentational/Transactions';
import useTransactions from '../hooks/useTransactions';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TransactionsContainer = () => {
    const { fetchNextPage } = useTransactions();
    const transactions = useSelector(getTransactionsList);
    const isLastPage = useSelector(getIsLastPage);
    const IsFetching = useSelector(getTransactionsIsFetching);
    const error = useSelector(getTransactionsFetchError);
    const translate = useTranslate('transactionsList');

    const ref = useInfiniteTransactionScroll(fetchNextPage, IsFetching);

    const formatted = useMemo(() => formattedTransactions(transactions), [
        transactions,
    ]);

    return (
        <PageContainer className="transactions-container">
            <AdvertsContainer />
            <DataCheck
                data={transactions}
                isLoading={IsFetching}
                error={error}
                noDataMessage={translate('transactionsList')}
                errorMessage={translate('error')}
            >
                <InfiniteScroll
                    className="transactions-scroll-container"
                    ref={ref}
                    last={isLastPage}
                >
                    <Transactions data={formatted} />
                </InfiniteScroll>
            </DataCheck>
        </PageContainer>
    );
};

export default TransactionsContainer;
