import React from 'react';
import { useDispatch } from 'react-redux';
import { useValidateConfirmPassword } from 'suits-me-npm/hooks';
import { createPassword } from 'suits-me-npm/actions';
import { useForm } from 'suits-me-npm/utils';
import Password from '../presentational/Password';

const PasswordContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();
    const [form, handleChange] = useForm({
        password: '',
        confirmPassword: '',
    });
    const [
        validatePassword,
        validateConfirmPassword,
    ] = useValidateConfirmPassword(
        form.password,
        form.confirmPassword,
        'confirmPassword',
    );

    const handleSubmit = () => {
        dispatch(createPassword(form));
    };

    return (
        <Password
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            validatePassword={validatePassword}
            validateConfirmPassword={validateConfirmPassword}
        />
    );
};

export default PasswordContainer;
