import React from 'react';
import { MORE } from 'constants/shared/routes';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import ChangePassword from '../presentational/ChangePassword';
import { useUpdatePassword } from 'suits-me-npm/hooks';
import { useHistory } from 'react-router-dom';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ChangePasswordContainer = () => {
    const history = useHistory();

    const {
        formData,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useUpdatePassword(handlePostSucess);

    const translate = useTranslate('personalDetails');
    return (
        <PageContainer className="more-change-password-container">
            <SectionTitle
                title={translate('changePassword')}
                backRoute={MORE}
            />
            <ChangePassword
                {...formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isPosting={isPosting}
                error={error}
            />
        </PageContainer>
    );

    function handlePostSucess() {
        return history.replace('/more/personal');
    }
};

export default ChangePasswordContainer;
