import React from 'react';
import { useDispatch } from 'react-redux';
import { createPassCode } from 'suits-me-npm/actions';
import { useForm } from 'suits-me-npm/utils';
import SetPasscode from '../presentational/SetPasscode';

const SetPasscodeContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({
        securityCode: '',
    });

    const handleSubmit = () => {
        dispatch(createPassCode(form));
    };

    return (
        <SetPasscode
            {...form}
            isPosting={isPosting}
            error={error}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
};

export default SetPasscodeContainer;
