import React from 'react';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import { SUITS_ME_CASHBACK_REWARDS } from 'constants/shared/routes';

const CashbackRewards = () => {
    return (
        <PageContainer className="more-cashback-rewards-container">
            <SectionTitle title="Cashback Rewards" />
            <div className="cashback-rewards-container">
                <h1 className="title">Cashback Rewards</h1>
                <h2 className="headline">
                    Earn While you Spend with Your Suits Me Card!
                </h2>

                <p className="text">
                    We have all your shopping needs covered! Shop in-store,
                    online or over the phone with your Suits Me card at our
                    cashback reward retailers and earn money while you spend.
                </p>

                <p className="text">
                    When you use your Suits me card in our cashback partner's
                    retail stores or online via their websites, you will receive
                    money back into your account the following month.
                </p>
            </div>
            <ButtonContainer>
                <Button href={SUITS_ME_CASHBACK_REWARDS}>See More</Button>
            </ButtonContainer>
        </PageContainer>
    );
};

export default CashbackRewards;
