import React from 'react';
import { useDispatch } from 'react-redux';
import CarouselButtons from '../presentational/CarouselButtons';
import { cardSatusValues } from 'suits-me-npm/constants';
import { defrostCard, freezeCard } from 'suits-me-npm/actions';
const { BLOCKED, LOST, STOLEN, DAMAGED, ISSUED } = cardSatusValues;

const CarouselButtonsContainer = ({
    onPrevClick,
    onNextClick,
    onViewPinClick,
    start,
    end,
    card,
}) => {
    const dispatch = useDispatch();

    const isFrozen = card.status === BLOCKED;
    const isDisabledCard = [LOST, STOLEN, DAMAGED, ISSUED, 'N'].includes(
        card.status,
    );

    const toggleFreeze = () => {
        if (isFrozen) dispatch(defrostCard(card.cardSerial));
        else dispatch(freezeCard(card.cardSerial));
    };

    return (
        <CarouselButtons
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            onViewPinClick={onViewPinClick}
            toggleFreeze={toggleFreeze}
            start={start}
            end={end}
            isFrozen={isFrozen}
            isDisabledCard={isDisabledCard}
        />
    );
};

export default CarouselButtonsContainer;
