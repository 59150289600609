import React, { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';
import withFieldValidation from '../hocs/withFieldValidation';

const DatePicker = ({
    value,
    onChange,
    onBlur = () => {},
    maxDate,
    name,
    placeholderText = `Please select ${name}`,
    required = true,
    minDate = dayjs()
        .subtract(100, 'years')
        .toDate(),
    showTimeSelect = false,
    showMonthDropdown = true,
    showYearDropdown = true,
    showError,
    classes,
    omitClear = false,
}) => {
    useEffect(() => {
        const datePickers = document.getElementsByClassName(
            'react-datepicker__input-container',
        );
        Array.from(datePickers).forEach(el =>
            el.childNodes[0].setAttribute('readOnly', true),
        );
    }, []);

    return (
        <div className="date-picker">
            <ReactDatePicker
                id={name}
                selected={value}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleChange}
                dateFormat={['dd/MM/yyyy']}
                placeholderText={placeholderText}
                required={required}
                onBlur={handleBlur}
                showTimeSelect={showTimeSelect}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode="select"
                withPortal
                className={classes}
            />
            {!omitClear && (
                <div className="icon-wrapper" onClick={() => handleDateClear()}>
                    <i className="far fa-times-circle" />
                </div>
            )}
        </div>
    );

    function handleChange(date) {
        onChange(name, date);
    }

    function handleBlur() {
        onBlur();
        showError();
    }

    function handleDateClear() {
        onChange(name, '');
    }
};

export default withFieldValidation(DatePicker);
