import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { twCheckAccountExists } from 'suits-me-npm/actions';
import { getTwUserAccountState } from 'suits-me-npm/selectors';

import CheckInternationalUser from '../presentational/CheckInternationalUser';
import Container from 'components/shared/generic/container/presentational/Container';
import { twCheckUnknownStatus } from 'constants/shared/transferwise';
// import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';

const CheckInternationalUserContainer = () => {
    const dispatch = useDispatch();
    const accountStatus = useSelector(getTwUserAccountState);

    const handleFetchExistingUser = () => dispatch(twCheckAccountExists());

    useEffect(() => {
        if (twCheckUnknownStatus(accountStatus)) {
            handleFetchExistingUser();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <CheckInternationalUser accountStatus={accountStatus} />
        </Container>
    );
};

export default CheckInternationalUserContainer;
