import React from 'react';

import { languageCodeOptions } from 'suits-me-npm/languages/constants';

import Switch from 'components/shared/generic/switch/presentational/Switch';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Select from 'components/shared/form/presentational/Select';
import Field from 'components/shared/form/presentational/Field';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const Settings = ({
    handleDarkModeToggle,
    isDarkModeEnabled,
    selectedLanguage,
    handleLanguageChange,
}) => {
    const translate = useTranslate('appSettings');

    return (
        <div className="settings-container">
            <Field>
                <SectionTitle
                    title={translate('preferredLanguage')}
                    secondary
                />
                <Select
                    name="languageCode"
                    onChange={handleLanguageChange}
                    value={selectedLanguage}
                    options={languageCodeOptions}
                    classes="generic-input modal-select"
                    required
                />
            </Field>
            <div className="setting">
                <SectionTitle title={translate('darkMode')} secondary />
                <Switch
                    value={isDarkModeEnabled}
                    onChange={() => handleDarkModeToggle(!isDarkModeEnabled)}
                    tracking
                    trackingLabel="DARK_MODE_SWITCH_CLICK"
                />
            </div>

            {/* <div className="setting">
                <SectionTitle title="Enable Quick Access" secondary />
                <Switch />
            </div> */}
        </div>
    );
};

export default Settings;
