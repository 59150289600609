import React from 'react';

import ModalOuterContainer from '../containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import { useHistory } from 'react-router-dom';
import { MORE_MANAGE_SUBSCRIPTION } from 'constants/shared/routes';

const PremiumAccountModal = ({ feature = 'this feature', hideModal }) => {
    const history = useHistory();

    const handleUpgrade = e => {
        e.preventDefault();
        history.push(MORE_MANAGE_SUBSCRIPTION);
        hideModal();
    };

    return (
        <ModalOuterContainer className="premium-account-modal" customButtons>
            <div className="description premium-account">
                <Typography tag="h1">
                    A premium account is required for this feature
                </Typography>
                <Typography className="generic-text intro-text">
                    Please upgrade to a premium to unlock {feature}.
                </Typography>
            </div>
            <ButtonContainer>
                <Button className="cancel" onClick={hideModal}>
                    Back
                </Button>
                <Button onClick={handleUpgrade}>Upgrade</Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default PremiumAccountModal;
