import React from 'react';
import { ProfileIcon } from 'constants/shared/icons';

const RecipientItem = ({ item }) => {
    return (
        <div className="item-area">
            <div className="item-left">
                <div className="item-avatar">
                    <ProfileIcon />
                </div>
                <div className="item-name-container">
                    <div className="item-name">{item.fullName}</div>
                    <div className="item-name-secondary">{item.accountSummary}</div>
                </div>
            </div>
            <div className="item-right">
                <div className="item-desc">{item.currency}</div>
            </div>
        </div>
    );
};

export default RecipientItem;
