import React from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { postLogin } from 'suits-me-npm/actions';
import { useForm } from 'suits-me-npm/utils';
import Login from '../presentational/Login';
import useRecaptcha from 'utils/hooks/useRecaptcha';

const LoginContainer = ({ isPosting, error }) => {
    const dispatch = useDispatch();
    const [getCaptchaToken, captchaLoading] = useRecaptcha();
    
    const [form, handleChange] = useForm({
        username: '',
        password: '',
        dob: '',
    });

    const handleSubmit = async () => {
        const token = await getCaptchaToken();

        const postBody = {
            ...form,
            dob: dayjs(form.dob).format('YYYY-MM-DD'),
            captchaToken: token,
        };
        
        dispatch(postLogin(postBody));
    };


    return (
        <Login
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting || captchaLoading}
            error={error}
        />
    );
};

export default LoginContainer;
