import { useDispatch, useSelector } from 'react-redux';
import { setLanguageCode } from 'suits-me-npm/actions/languages';
import { getLanguage } from 'suits-me-npm/selectors';
import useLocalStorage from 'utils/hooks/useLocalStorage';

export default function useSelectLanguage() {
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const [, setLangInStorage] = useLocalStorage('languageCode', null);

    const handleChange = (_, value) => {
        setLangInStorage(value);

        dispatch(setLanguageCode(value));
    };

    return [language, handleChange];
}
