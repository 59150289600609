import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchZendeskArticles,
    fetchZendeskCategories,
} from 'suits-me-npm/actions';
import {
    getZendeskArticles,
    getZendeskError,
    getZendeskIsFetching,
    getZendeskCategory,
} from 'suits-me-npm/selectors';
import { HELP } from 'constants/shared/routes';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import ZendeskFAQsItem from '../presentational/ZendeskFAQsItem';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { isEmpty } from 'suits-me-npm/utils';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ZendeskFAQsItemContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const faqs = useSelector(state => getZendeskCategory(state, id));
    const faqsItem = useSelector(state => getZendeskArticles(state, id));
    const isFetching = useSelector(getZendeskIsFetching);
    const error = useSelector(getZendeskError);

    useEffect(() => {
        if (!faqs || isEmpty(faqs)) {
            dispatch(fetchZendeskCategories());
        }
        dispatch(fetchZendeskArticles(id));
    }, []);

    const state = faqs.name || !isEmpty(faqs);

    const translate = useTranslate('faqs');

    return (
        <PageContainer className="help-container">
            <SectionTitle title={`Help - ${state || ''}`} backRoute={HELP} />
            <DataCheck
                noDataMessage={translate('noDataMessage')}
                data={faqsItem}
                isLoading={isFetching}
                error={error}
            >
                <ZendeskFAQsItem faqs={faqsItem} />
            </DataCheck>
        </PageContainer>
    );
};

export default ZendeskFAQsItemContainer;
