import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getPayeesIsPosting,
    getPayeesPostError,
    getPayeesPostSuccess,
} from 'suits-me-npm/selectors/payees';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CreatePayee from '../presentational/CreatePayee';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import useCreatePayee from '../hooks/useCreatePayee';
import { getIsEuro } from 'suits-me-npm/selectors';

const CreatePayeeContainer = () => {
    const location = useLocation();
    const error = useSelector(getPayeesPostError);
    const isPosting = useSelector(getPayeesIsPosting);
    const postSuccess = useSelector(getPayeesPostSuccess);
    const isEuro = useSelector(getIsEuro);
    const backRoute = location.state ? location.state.backRoute : null;
    const translate = useTranslate('addPayee');

    const { formData, handleChange, handleSubmit } = useCreatePayee(
        postSuccess,
    );

    return (
        <PageContainer className="manage-payee-container">
            <SectionTitle
                title={translate('titleText')}
                backRoute={backRoute}
            />
            <CreatePayee
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                isPosting={isPosting}
                isEuro={isEuro}
            />
        </PageContainer>
    );
};

export default CreatePayeeContainer;
