import React from 'react';
import { formatAlphabetList, sortArrayOfObjs } from 'utils/generic';
import RecipientItem from 'components/shared/layout/recipientItem/presentational/RecipientItem';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const SelectRecipient = ({ handleClick, searchTerm, handleSearchTerm, result, list }) => {
    const translate = useTranslate('internationalPayment')
    const formattedList = formatAlphabetList(list, 'fullName')

    return (
        <div className="select-recipient-container">
            <div className="search-and-list">
                <div className="search-input-container">
                    <i className="fas fa-search" />
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearchTerm}
                        value={searchTerm}
                    />
                </div>
                <div className="search-and-list-container">
                    {!searchTerm ? (
                        <div className="search-list-container">
                            {Object.keys(formattedList).map((item, index) => {
                                return <div key={`list-${index}`}>
                                    <h3>{item}</h3>
                                    {formattedList[item].map((listItem, listIndex) => {
                                        return (
                                            <div key={`item-${index}-${listIndex}`}
                                                onClick={() => handleClick(listItem.id)}
                                                className="search-and-list-item-container"
                                            >
                                                <RecipientItem item={listItem} />
                                            </div>
                                        )
                                    })}
                                </div>
                            })}
                        </div>
                    ) : (
                        <div className="search-list-container">
                            <h3>{translate('searchList.result')}</h3>
                            {searchTerm && result.length ? (
                                <div>
                                    {sortArrayOfObjs(result, 'fullName').map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(item.id)}
                                            className="search-and-list-item-container"
                                        >
                                            <RecipientItem item={item} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="results-errors">{translate('searchList.noResults')}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SelectRecipient;
