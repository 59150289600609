import React from 'react';
import MenuItem from 'components/shared/generic/menuItem/presentational/MenuItem';

const ZendeskFAQs = ({ faqs }) => {
    return (
        <div className="zendesk-faqs">
            {faqs.map(({ name, id }) => {
                return (
                    <MenuItem
                        tracking
                        trackingLabel={`FAQS_CATEGORY_${name}`}
                        key={id}
                        title={name}
                        to={`/help/${id}`}
                    />
                );
            })}
        </div>
    );
};

export default ZendeskFAQs;
