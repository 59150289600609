import React from 'react';

import { isEmpty } from 'suits-me-npm/utils';
import PoweredByTransferwise from '_content/images/transferwise/powered-by.png';

import ReactCurrencyInput from 'react-currency-input';
import Button from 'components/shared/generic/button/presentational/Button';
import ComponentDataCheck from '../../componentDataCheck/presentational/ComponentDataCheck';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import { useSelector } from 'react-redux';
import { getEuroCurrency } from 'suits-me-npm/selectors';

const InternationalQuoteCalculator = ({
    handleShowModal,
    targetCurrency,
    sourceAmount,
    handleSourceAmount,
    tempQuoteIsFetching,
    tempQuote,
    buttonText,
    onContinueClick,
}) => {
    const translate = useTranslate('internationalPayment');
    const userCurrency = useSelector(getEuroCurrency);
    const currency = userCurrency.toUpperCase() || 'gbp';

    return (
        <div className="international-quote-calculator-container">
            <div className="source-container">
                <div className="amount-box">
                    <div className="amount-input-container">
                        <p className="input-header">
                            {translate('quoteCalc.youSend')}
                        </p>
                        <ReactCurrencyInput
                            value={sourceAmount}
                            thousandSeparator=""
                            onChangeEvent={handleSourceAmount}
                        />
                    </div>
                </div>
                <div className="currency-area">
                    <div
                        className={`currency-flag currency-flag-${userCurrency}`}
                    />
                    <div className="currency-code">{currency}</div>
                </div>
            </div>
            <div className="information-container">
                <div className="grid-1"></div>
                <div className="information-content">
                    <div className="information-fee">
                        £
                        <ComponentDataCheck
                            data={tempQuote}
                            isLoading={tempQuoteIsFetching}
                        >
                            {tempQuote.feeTotal}
                        </ComponentDataCheck>
                        {translate('quoteCalc.fee')}
                    </div>
                    <div className="information-rate">
                        <ComponentDataCheck
                            data={tempQuote}
                            isLoading={tempQuoteIsFetching}
                        >
                            {(Math.round(tempQuote.rate * 100) / 100).toFixed(
                                2,
                            )}
                        </ComponentDataCheck>
                        {translate('quoteCalc.exchangeRate')}
                    </div>
                </div>
            </div>
            <div className="recipient-container">
                <div className="amount-box">
                    <div className="amount-input-container">
                        <p className="input-header">
                            {translate('quoteCalc.recipientGets')}
                        </p>
                        <div className="input-value">
                            <ComponentDataCheck
                                data={tempQuote}
                                isLoading={tempQuoteIsFetching}
                            >
                                {tempQuote.targetAmount}
                            </ComponentDataCheck>
                        </div>
                    </div>
                </div>
                <div
                    onClick={handleShowModal}
                    className="currency-area recipient"
                >
                    <div
                        className={`currency-flag currency-flag-${targetCurrency.toLowerCase()}`}
                    />
                    <div className="currency-code">{targetCurrency}</div>
                    <i className="fas fa-chevron-down" />
                </div>
            </div>
            <p className="arrival-time">
                {translate('quoteCalc.shouldArrive')}
                <ComponentDataCheck
                    data={tempQuote}
                    isLoading={tempQuoteIsFetching}
                >
                    {tempQuote.formattedEstimatedDelivery}
                </ComponentDataCheck>
            </p>
            <div className="international-calculator-button">
                <div className="powered-by-transferwise">
                    <img
                        src={PoweredByTransferwise}
                        alt="Powered By Transferwise"
                    />
                </div>
                <Button
                    onClick={event =>
                        onContinueClick(event, sourceAmount, targetCurrency)
                    }
                    disabled={isEmpty(tempQuote)}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default InternationalQuoteCalculator;
