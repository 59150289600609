import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';

const PaymentSuccess = ({ onSuccessClick, data }) => {
    return (
        <div className="payment-status-container full-width">
            <div className="status-icon">
                <i className="fas fa-check-circle"></i>
            </div>
            <div className="status-message">
                You have sent {data.amount} to {data.recipient}
            </div>
            <Button onClick={onSuccessClick}>Return to payments</Button>
        </div>
    );
};

export default PaymentSuccess;
