import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

const TextInput = ({
    type = 'text',
    name,
    placeholder,
    classes,
    value,
    onChange,
    charLimit,
    minLength,
    maxNum,
    disabled,
    showError,
    appendIcon,
    prependIcon,
}) => {
    return (
        <div className={`sm-input ${disabled ? 'disabled' : ''}`}>
            {prependIcon && (
                <span className="sm-input-prepend-icon">{appendIcon}</span>
            )}
            {appendIcon && (
                <span className="sm-input-append-icon">{appendIcon}</span>
            )}
            <input
                className={classes}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={newVal => {
                    onChange(name, newVal.target.value);
                }}
                onBlur={showError}
                minLength={minLength}
                maxLength={charLimit}
                max={maxNum}
                disabled={disabled}
            />
        </div>
    );
};

export default withFieldValidation(TextInput);
