import React from 'react';
import PaymentSwitch from './PaymentSwitch';
import InternationalPayeesContainer from '../../international/main/containers/InternationalPayeeContainer';
import UKPayeesContainer from '../../uk/main/containers/UKPayeeContainer';

const MakePayment = ({ active, ukClick, intClick }) => {
    return (
        <div className="make-payment">
            <PaymentSwitch
                active={active}
                ukClick={ukClick}
                intClick={intClick}
            />
            {!active ? <UKPayeesContainer /> : <InternationalPayeesContainer />}
        </div>
    );
};

export default MakePayment;
