import Button from 'components/shared/generic/button/presentational/Button';
import React from 'react';
import { createPortal } from 'react-dom';

export default function HelpButton() {
    const [rcBadge, setRcBadge] = React.useState(null);

    new MutationObserver(() => {
        setRcBadge(document.getElementsByClassName('grecaptcha-badge').item(0));
    }).observe(document.body, { childList: true });

    const nodes = (
        <Button
            href="https://support.suitsmecard.com/hc/en-gb/requests/new"
            className={`help-button ${rcBadge != null ? 'rc-offset' : ''}`}
        >
            Help
        </Button>
    );

    const element = document.getElementById('root');
    if (element == null) return null;

    return createPortal(nodes, element);
}
