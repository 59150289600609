import React from 'react';
import { TW_AUTH_LINK_URL, TW_REDIRECT_URI, TW_CLIENT_ID } from 'config';
import TransferwiseLogo from '_content/images/icons/transferwise/transferwise-logo.png';
import analytics from 'utils/analytics';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TransferWiseLinkExisting = () => {
    const translate = useTranslate('internationalPayment.check.linkExisting')
    return (
        <div className="transferwise-link-existing">
            <div className="transferwise-logo-wrapper">
                <img
                    src={TransferwiseLogo}
                    alt="transferwise-logo"
                    className="transferwise-logo"
                />
            </div>
            <div className="subtitle-wrapper narrow">
                <h3>
                    {translate('youAlready')}
                </h3>
            </div>
            <div className="terms-links-wrapper">
                <a
                    href={`${TW_AUTH_LINK_URL}/?client_id=${TW_CLIENT_ID}&redirect_uri=${TW_REDIRECT_URI}`}
                    className="button"
                    onClick={() =>
                        analytics.sendLinkEvent(
                            'LINK_CLICK',
                            'TRANSFERWISE_PRIVACY_PRIVACY',
                        )
                    }
                >
                    {translate('connect')}
                </a>
            </div>
        </div>
    );
};

export default TransferWiseLinkExisting;
