import { combineReducers } from 'redux';
import * as sharedReducers from 'suits-me-npm/reducers';
import modalReducer from './modal';
import transferWiseQuoteReducer from './tw-quote';
import redirectReducer from './redirect';
import darkModeReducer from './isDarkModeEnabled';

const rootReducer = combineReducers({
    modalReducer,
    redirectReducer,
    transferWiseQuoteReducer,
    darkModeReducer,
    ...sharedReducers,
});

export default (state, action) =>
    rootReducer(action.type === 'LOGOUT' ? undefined : state, action);
