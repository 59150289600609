import React from 'react';
import dayjs from 'dayjs';
import { formattedCardNumber } from 'suits-me-npm/utils';

const NormalCard = ({ info }) => {
    const cardNumber = formattedCardNumber(info.cardPan);

    return (
        <div className="content normal-card">
            <p className="expiry-date">
                {dayjs(info.expiryDate).format('YY/MM')}
            </p>
            <span className={'card-number'}>{cardNumber}</span>
            <p className="name">{info.cardHolderName}</p>
        </div>
    );
};

export default NormalCard;
