import React, { useEffect } from 'react';

import DirectDebit from '../presentational/DirectDebit';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import Container from 'components/shared/generic/container/presentational/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount, getDirectDebits } from 'suits-me-npm/selectors';
import { fetchDirectDebits } from 'suits-me-npm/actions';
import { accountRoles } from 'suits-me-npm/constants';
import UpgradeToView from '../presentational/UpgradeToView';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { PAYMENT } from 'constants/shared/routes';

const DirectDebitContainer = () => {
    const dispatch = useDispatch();
    const account = useSelector(getAccount);
    const translate = useTranslate('directDebits');

    useEffect(() => {
        dispatch(fetchDirectDebits());
    }, []);

    const shouldForbid = account.productType === accountRoles.Essential;
    if (shouldForbid) return <UpgradeToView />;
    const directDebits = useSelector(getDirectDebits);
    const data = Object.values(directDebits);
    return (
        <Container>
            <SectionTitle title={translate('titleText')} backRoute={PAYMENT} />
            <DataCheck data={data} noDataMessage={translate('noDataMessage')}>
                <DirectDebit data={data} />
            </DataCheck>
        </Container>
    );
};

export default DirectDebitContainer;
