import React from 'react';
import { useHistory } from 'react-router-dom';

import { TRANSFERWISE_SELECT_RECIPIENT } from 'constants/shared/routes';
import useStoredQuote from 'utils/hooks/useStoredQuote';

import ReviewQuote from '../presentational/ReviewQuote';
import Container from 'components/shared/generic/container/presentational/Container';

const ReviewQuoteContainer = () => {
    const history = useHistory();
    // eslint-disable-next-line
    const { storedQuote } = useStoredQuote();

    const handleSelectRecipient = () => history.push(TRANSFERWISE_SELECT_RECIPIENT);

    return (
        <Container>
            <ReviewQuote onContinueClick={handleSelectRecipient} storedQuote={storedQuote} />
        </Container>
    );
};

export default ReviewQuoteContainer;
