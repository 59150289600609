import React from 'react';

const GridContainer = ({
    className,
    col = '1',
    gap = '0',
    fullWidth,
    children,
}) => {
    const classes = [];
    if (fullWidth) classes.push('full-width');
    if (className) classes.push(className);

    return (
        <div
            className={`sm-grid-container ${classes.join(' ')}`}
            style={{
                gridTemplateColumns: `repeat(${col}, 1fr)`,
                gap: `${gap}px`,
            }}
        >
            {children}
        </div>
    );
};

export default GridContainer;
