import React from 'react';
import zxcvbn from 'zxcvbn';
import { passwordLabel } from 'constants/shared/enums';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const PasswordMeter = ({ password }) => {
    const { score } = zxcvbn(password);
    const translate = useTranslate('passwordMeter');

    return (
        <div className="meter-container">
            <progress
                className={`password-strength-meter-progress strength-${passwordLabel[score]}`}
                value={score === 0 ? 1 : score}
                min="1"
                max="4"
            />
            <br />
            <label className="password-strength-meter-label">
                {/* {password && ( */}
                <span>
                    <strong>{translate('passwordStrength')}:</strong>{' '}
                    {translate(passwordLabel[score].toLowerCase())}
                </span>
                {/* )} */}
            </label>
        </div>
    );
};

export default PasswordMeter;
