import React from 'react';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import { MORE_CHANGE_PASSWORD } from 'constants/shared/routes';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const PersonalDetails = ({
    data,
    editClick /*showPrimaryTaxChange <-- For primary tax */,
}) => {
    const {
        title,
        forename,
        surname,
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        addressLine5,
        town,
        postcode,
        country,
        countryCode,
        email,
        tel1,
        tel2,
        // primaryTaxResidency, <-- For primary tax
    } = data;
    const translate = useTranslate('personalDetails');

    return (
        <>
            <div className="personal-details">
                <Typography tag="h1">{translate('name')}</Typography>
                <Typography>{`${title} ${forename} ${surname}`}</Typography>
            </div>
            <div className="personal-details">
                <Typography tag="h1">{translate('email')}</Typography>
                <Typography>{email}</Typography>
            </div>
            <div className="personal-details spacing-bottom">
                <Typography tag="h1">{translate('phoneNumbers')}</Typography>
                <Typography>{tel1}</Typography>
                <Typography>{tel2}</Typography>
            </div>
            <div className="personal-details spacing-bottom">
                <Typography tag="h1">{translate('address')}</Typography>
                <Typography>{addressLine1},</Typography>
                {addressLine2 && <Typography>{addressLine2},</Typography>}
                {addressLine3 && <Typography>{addressLine3},</Typography>}
                {addressLine4 && <Typography>{addressLine4},</Typography>}
                {addressLine5 && <Typography>{addressLine5},</Typography>}
                <Typography>{town},</Typography>
                <Typography>{country},</Typography>
                {countryCode && <Typography>{countryCode},</Typography>}
                <Typography>{postcode}</Typography>
            </div>
            <ButtonContainer>
                <Button to={MORE_CHANGE_PASSWORD}>
                    {translate('changePassword')}
                </Button>
                <Button onClick={editClick}>{translate('edit')}</Button>
            </ButtonContainer>
        </>
    );
};

export default PersonalDetails;
