import React from 'react';
import { Switch, Route as ReactRoute } from 'react-router-dom';

import NotFound from 'components/shared/notFound/page/NotFound';

const SwitchWith404 = ({ children }) => (
    <Switch>
        {children}
        <ReactRoute component={NotFound} />
    </Switch>
);

export default SwitchWith404;
