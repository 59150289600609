import React from 'react';

import RootStack from './components/RootStack';

import activityRoutes from './stacks/activity';
import authRoutes from './stacks/auth';
import cardsRoutes from './stacks/cards';
import helpRoutes from './stacks/help';
import internationalRoutes from './stacks/internationalPayments';
import moreRoutes from './stacks/more';
import paymentsRoutes from './stacks/payments';

export const rootStack = [
    { base: '/auth', stack: authRoutes, auth: false },
    { base: '/more', stack: moreRoutes, auth: true },
    { base: '/cards', stack: cardsRoutes, auth: true },
    { base: '/international', stack: internationalRoutes, auth: true },
    { base: '/payments', stack: paymentsRoutes, auth: true },
    { base: '/help', stack: helpRoutes, auth: true },
    // This has to always be at the bottom.
    { base: '/', stack: activityRoutes, auth: true },
];

const Routes = () => <RootStack stack={rootStack} />;
export default Routes;
