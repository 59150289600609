import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useForm } from 'suits-me-npm/utils';
import { showModal } from 'actions/modal';
import { AUTHORIZE_PAYMENT_MODAL } from 'constants/shared/modalTypes';
import { PAYMENT } from 'constants/shared/routes';

import Container from 'components/shared/generic/container/presentational/Container';
import SendPayments from '../presentational/SendPayment';
import {
    getTransactionsIsPosting,
    getTransactionsPostError,
} from 'suits-me-npm/selectors';

const SendPaymentsContainer = () => {
    const history = useHistory();
    const [form, handleChange] = useForm({
        recipientName: '',
        amount: 0,
        recipientAccountNumber: '',
        recipientSortCode: '',
        reference: '',
    });

    const dispatch = useDispatch();

    const error = useSelector(getTransactionsPostError);
    const isPosting = useSelector(getTransactionsIsPosting);

    const handleSubmit = () => {
        const {
            recipientName,
            amount,
            recipientAccountNumber,
            recipientSortCode,
            reference,
        } = form;

        const paymentInfo = {
            recipientName,
            amount,
            recipientAccountNumber,
            recipientSortCode,
            reference,
        };

        dispatch(
            showModal({
                type: AUTHORIZE_PAYMENT_MODAL,
                props: { paymentInfo },
            }),
        );
    };

    const handleBack = () => {
        history.push(PAYMENT);
    };

    return (
        <Container>
            <SendPayments
                {...form}
                error={error}
                isPosting={isPosting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleBack={handleBack}
            />
        </Container>
    );
};

export default SendPaymentsContainer;
