import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    getTwRecipients,
    getTwRecipientsIsFetching,
    getTwRecipientsError,
    getTwQuote,
} from 'suits-me-npm/selectors';
import { twFetchRecipients, twSelectRecipient } from 'suits-me-npm/actions';
import { isEmpty } from 'suits-me-npm/utils';
import { searchCurrencies } from 'utils/generic';
import useCheckTransferwiseQuote from 'utils/hooks/useCheckTransferwiseQuote';
import useSearchAndList from 'utils/hooks/useSearchAndList';
import {
    TRANSFERWISE_ADD_RECIPIENT,
    TRANSFERWISE_MORE_DETAILS,
    PAYMENT_MAKE_PAYMENT,
    TRANSFERWISE_REVIEW_QUOTE,
} from 'constants/shared/routes';

import Container from 'components/shared/generic/container/presentational/Container';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import SelectRecipient from '../presentational/SelectRecipient';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const SelectRecipientContainer = () => {
    const translate = useTranslate('internationalPayment')
    const dispatch = useDispatch();
    const history = useHistory();
    useCheckTransferwiseQuote();
    const quote = useSelector(getTwQuote);
    const recipientsError = useSelector(getTwRecipientsError);
    const recipientsIsFetching = useSelector(getTwRecipientsIsFetching);
    const recipients = useSelector(getTwRecipients);
    const { searchTerm, displayedList, handleSearchTerm } = useSearchAndList(
        onSearchChange,
    );

    const handleAddNewClick = () => history.push(TRANSFERWISE_ADD_RECIPIENT);

    const handleSelectRecipient = id => {
        dispatch(twSelectRecipient(id));
        history.push(TRANSFERWISE_MORE_DETAILS);
    };

    function onSearchChange(searchTerm) {
        return searchCurrencies(recipients, ['fullName'], searchTerm);
    }

    useEffect(() => {
        if (isEmpty(quote)) {
            history.push(PAYMENT_MAKE_PAYMENT);
        }

        dispatch(twFetchRecipients(quote.targetCurrency));
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <SectionTitle
                title={translate('selectRecipient.title')}
                backRoute={TRANSFERWISE_REVIEW_QUOTE}
            />
            <DataCheck
                isLoading={recipientsIsFetching}
                error={recipientsError}
                data={recipients}
                noDataMessage={translate('selectRecipient.noDataMessage')}
            >
                <SelectRecipient
                    handleClick={handleSelectRecipient}
                    handleSearchTerm={handleSearchTerm}
                    searchTerm={searchTerm}
                    result={displayedList}
                    list={recipients}
                />
            </DataCheck>
            <ButtonContainer>
                <Button
                    size="medium"
                    className="center"
                    onClick={handleAddNewClick}
                    tracking
                    trackingLabel="ADD_NEW_RECIPIENT_CLICK"
                >
                    {translate('selectRecipient.addNewRecipient')}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default SelectRecipientContainer;
