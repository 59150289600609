import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
    twClearAllQuotes,
    twClearTransactionsDetails,
    twClearRecipient,
} from 'suits-me-npm/actions';
import { twUseReviewAndSendTransaction } from 'suits-me-npm/hooks';
import { getTwRecipientSelectedId } from 'suits-me-npm/selectors';
import {
    TRANSFERWISE_STATUS,
    PAYMENT_MAKE_PAYMENT,
} from 'constants/shared/routes';
import useStoredQuote from 'utils/hooks/useStoredQuote';
import usePaymentSuccessful from 'utils/hooks/usePaymentSuccessful';
import useCheckTransferwiseQuote from 'utils/hooks/useCheckTransferwiseQuote';

import Container from 'components/shared/generic/container/presentational/Container';
import ReviewAndSend from '../presentational/ReviewAndSend';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ReviewAndSendContainer = () => {
    const translate = useTranslate('internationalPayment')
    const dispatch = useDispatch();
    const history = useHistory();
    useCheckTransferwiseQuote();
    const { setStoredQuote } = useStoredQuote();
    const { setPaymentSuccessful } = usePaymentSuccessful();
    const selectedRecipientId = useSelector(getTwRecipientSelectedId);
    const [details, onSubmit] = twUseReviewAndSendTransaction();
    const { isPosting, postSuccess, error, recipient, quote } = details;

    const handleClearQuotes = () => {
        setPaymentSuccessful({
            targetAmount: quote.targetAmount,
            targetCurrency: quote.targetCurrency,
            recipient: recipient.fullName,
        });
        setStoredQuote({});
        dispatch(twClearRecipient());
        dispatch(twClearAllQuotes());
        dispatch(twClearTransactionsDetails());
    };

    useEffect(() => {
        if (!selectedRecipientId) {
            history.push(PAYMENT_MAKE_PAYMENT);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (postSuccess) {
            handleClearQuotes();
            history.push(TRANSFERWISE_STATUS);
        }
        // eslint-disable-next-line
    }, [postSuccess]);

    return (
        <Container fullHeight>
            <DataCheck
                data={recipient}
                isLoading={isPosting}
                error={error}
                noDataMessage={translate('reviewAndSend.noDataMessage')}
            >
                <ReviewAndSend
                    handleSubmit={onSubmit}
                    isPosting={isPosting}
                    error={error}
                    recipient={recipient}
                    quote={quote}
                />
            </DataCheck>
        </Container>
    );
};

export default ReviewAndSendContainer;
