import React from 'react';

const Field = ({
    children,
    name = '',
    sizeClasses = 'col s12',
    classes = '',
    required = false,
    htmlFor,
    styles = {},
    ...props
}) => {
    return (
        <div
            className={`form-field ${sizeClasses} ${classes} `}
            style={{ ...styles }}
            {...props}
        >
            {name && name.length && (
                <label className="title" htmlFor={htmlFor}>
                    {name} {required && <sub>*</sub>}
                </label>
            )}
            {children}
        </div>
    );
};

export default Field;
