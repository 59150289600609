import React from 'react';
import { standingOrdersRecurringNames } from 'suits-me-npm/constants';
import dayjs from 'dayjs';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const StandingOrderText = ({
    selectedPayee,
    amount,
    startOn,
    endOn,
    recurType,
}) => {
    const translate = useTranslate('standingOrders.confirmModal');
    return (
        <>
            <Typography>{translate('youAreAboutToSend')}</Typography>
            <Typography tag="h2">{amount}</Typography>
            <Typography>{selectedPayee.payeeName}</Typography>
            <Typography>Acc No {selectedPayee.accountNo}</Typography>
            <Typography>{selectedPayee.sortCode}</Typography>
            <Typography>{translate('on')}</Typography>
            <Typography tag="h2">
                {dayjs(startOn).format('DD/MM/YYYY')}
            </Typography>
            <Typography>
                {translate('sendBasis', {
                    recurType: standingOrdersRecurringNames[recurType],
                })}
            </Typography>
            <Typography tag="h2">
                {dayjs(endOn).format('DD/MM/YYYY')}
            </Typography>
            <Typography>{translate('isThisCorrect')}</Typography>
        </>
    );
};

export default StandingOrderText;
