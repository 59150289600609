import React from 'react';
import ModalOuterContainer from '../../generic/containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import MakePaymentText from './MakePaymentText';
import StandingOrderText from './StandingOrderText';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ConfirmAndSendModal = ({
    selectedPayee,
    handleClose,
    handleSubmit,
    isPosting,
    error,
    type,
    ...props
}) => {
    const translate = useTranslate('generic');
    return (
        <ModalOuterContainer
            className="confirm-and-send-modal"
            customButtons={true}
        >
            <div className="description">
                {type === 'make-payment' && (
                    <MakePaymentText selectedPayee={selectedPayee} {...props} />
                )}

                {type === 'standing-order' && (
                    <StandingOrderText
                        {...props}
                        selectedPayee={selectedPayee}
                    />
                )}

                {!!error && (
                    <Typography className="error">
                        An error has occurred, please check you have sufficient
                        funds and try again.
                    </Typography>
                )}
            </div>
            <ButtonContainer marginTop="18px">
                <Button
                    className="confirm"
                    onClick={handleSubmit}
                    isPosting={isPosting}
                    tracking
                    trackingLabel={`SEND_${
                        type === 'standing-order'
                            ? 'STANDING_ORDER'
                            : 'UK_PAYMENT'
                    }`}
                >
                    {translate('confirm')}
                </Button>
                <Button className="cancel" onClick={handleClose}>
                    {translate('close')}
                </Button>
            </ButtonContainer>
        </ModalOuterContainer>
    );
};

export default ConfirmAndSendModal;
