import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { TW_REDIRECT_URI } from 'config';
import { twCreateAccount, twLinkExistingUser } from 'suits-me-npm/actions';
import { twReadyToTransfer } from 'constants/shared/transferwise';
import { TRANSFERWISE_REVIEW_QUOTE } from 'constants/shared/routes';

import Container from 'components/shared/generic/container/presentational/Container';
import TransferWiseLink from '../presentational/TransferwiseLink';

const TransferWiseLinkContainer = ({ accountStatus }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleCreateUserAccount = () => dispatch(twCreateAccount());

    const handleQuoteSummaryPage = () =>
        history.push(TRANSFERWISE_REVIEW_QUOTE);

    const linkAccount = useCallback((code, profileId) => {
        const postBody = {
            authorizationCode: code,
            profileId: Number(profileId),
            redirectUri: TW_REDIRECT_URI,
        };
        dispatch(twLinkExistingUser(postBody));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (twReadyToTransfer(accountStatus)) {
            handleQuoteSummaryPage();
        }
        // eslint-disable-next-line
    }, [accountStatus]);

    useEffect(() => {
        if (location.search) {
            const { code, profileId } = Object.fromEntries(
                new URLSearchParams(location.search),
            );
            linkAccount(code, profileId);
        }
        // eslint-disable-next-line
    }, [linkAccount]);

    return (
        <Container fullHeight flexColumn flexAlignCenter>
            <TransferWiseLink
                accountStatus={accountStatus}
                onCreateAccountClick={handleCreateUserAccount}
            />
        </Container>
    );
};

export default TransferWiseLinkContainer;
