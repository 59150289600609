import React from 'react';
import { connect } from 'react-redux';

import {
    ERROR_MODAL,
    SUCCESS_MODAL,
    ENTER_CVC_MODAL,
    STATUS_CARD_MODAL,
    ADD_NEW_CARDHOLDER,
    AUTHORIZE_PAYMENT_MODAL,
    SEARCH_AND_LIST_MODAL,
    PREMIUM_ACCOUNT_MODAL,
    CONFIRM_MODAL,
    CONFIRM_AND_SEND_MODAL,
    REGRADE_SUBSCRIPTION_MODAL,
    EXCESSIVE_FUNDS_MODAL,
    EXPORT_STATEMENT_MODAL,
    ACCOUNT_SELECT,
    DISMISS_MODAL,
} from 'constants/shared/modalTypes';
import { hideModal, showModal } from 'actions/modal';

import ErrorModal from '../presentational/ErrorModal';
import SuccessModal from '../presentational/SuccessModal';
import PremiumAccountModal from '../presentational/PremiumAccountModal';
import EnterCVCModalContainer from '../../enterCVCModal/containers/EnterCVCModalContainer';
import AddCardHolderModalContainer from '../../addCardHolderModal/containers/AddCardHolderModalContainer';
import StatusCardModalContainer from '../../statusCardModal/containers/StatusCardModalContainer';
import AuthorizePaymentModalContainer from '../../authorizePaymentModal/containers/AuthorizePaymentModalContainer';
import SearchAndListModalContainer from '../../searchAndList/containers/SearchAndListModalContainer';
import ConfirmAndSendModalContainer from '../../confirmAndSendModal/containers/ConfirmAndSendModalContainer';

import { getModalType, getModalProps } from 'selectors/modal';
import ConfirmModal from '../presentational/ConfirmModal';
import RegradeSubscriptionModal from 'components/shared/modals/generic/presentational/RegradeSubscriptionModal';
import ExcessiveFundsModal from 'components/shared/modals/generic/presentational/ExcessiveFundsModal';
import ExportStatementModal from '../../exportStatementModal/ExportStatementModal';
import AccountSelectModal from '../../accountSelect/AccountSelectModal';
import DismissModal from '../presentational/DismissModal';

const MODAL_COMPONENTS = {
    [ERROR_MODAL]: ErrorModal,
    [SUCCESS_MODAL]: SuccessModal,
    [CONFIRM_MODAL]: ConfirmModal,
    [DISMISS_MODAL]: DismissModal,
    [PREMIUM_ACCOUNT_MODAL]: PremiumAccountModal,
    [ENTER_CVC_MODAL]: EnterCVCModalContainer,
    [STATUS_CARD_MODAL]: StatusCardModalContainer,
    [ADD_NEW_CARDHOLDER]: AddCardHolderModalContainer,
    [AUTHORIZE_PAYMENT_MODAL]: AuthorizePaymentModalContainer,
    [SEARCH_AND_LIST_MODAL]: SearchAndListModalContainer,
    [CONFIRM_AND_SEND_MODAL]: ConfirmAndSendModalContainer,
    [REGRADE_SUBSCRIPTION_MODAL]: RegradeSubscriptionModal,
    [EXCESSIVE_FUNDS_MODAL]: ExcessiveFundsModal,
    [EXPORT_STATEMENT_MODAL]: ExportStatementModal,
    [ACCOUNT_SELECT]: AccountSelectModal,
};

const ModalRoot = ({ modalType, modalProps, ...otherProps }) => {
    if (!modalType) return null;

    const SpecificModal = MODAL_COMPONENTS[modalType];
    return <SpecificModal {...modalProps} {...otherProps} />;
};

const mapStateToProps = state => ({
    modalType: getModalType(state),
    modalProps: getModalProps(state),
});

const mapDispatchToProps = { hideModal, showModal };

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
