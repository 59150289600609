import React from 'react';

const Typography = ({ tag, className = '', styles, children, ...props }) => {
    const TagName = tag;

    return (
        <TagName
            {...props}
            className={`sm-typography ${className}`}
            style={styles}
        >
            {children}
        </TagName>
    );
};

Typography.defaultProps = {
    tag: 'p',
};

export default Typography;
