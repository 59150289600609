import React from 'react';
import ReactCodeInput from 'react-verification-code-input';
import withFieldValidation from '../hocs/withFieldValidation';

const CodeInput = ({
    name,
    placeholder,
    classes,
    defaultValues,
    onChange,
    onComplete = () => {},
    width,
    height,
    fields,
    showError,
    required,
}) => {
    const handleChange = value => {
        onChange(name, value);
    };

    const handleComplete = () => {
        onComplete();
        showError();
    };

    return (
        <ReactCodeInput
            name={name}
            placeholder={placeholder}
            className={classes}
            values={defaultValues}
            onChange={handleChange}
            onComplete={handleComplete}
            fieldWidth={width}
            fieldHeight={height}
            fields={fields}
            required={required}
            showError={showError}
        />
    );
};

export default withFieldValidation(CodeInput);
