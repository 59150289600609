import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-places-autocomplete';
import withFieldValidation from '../hocs/withFieldValidation';
import { getPostCode } from 'utils/autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';

const PostCodeAutoCompleteInput = ({ classes, onChange, countryCode }) => {
    const [autoCompleteVal, setAutoCompleteVal] = useState('');
    const [error, setError] = useState(null);

    const onPostCodeSelect = async (_address, value) => {
        let result = {};

        try {
            result = getPostCode(await geocodeByPlaceId(value));
        } catch (err) {
            return setError('Please enter a valid postcode');
        }

        if (!result.postcode || !result.country)
            setError('Please enter a valid postcode');

        onChange('addressLine1', '');
        onChange('addressLine2', '');
        onChange('addressLine3', '');
        onChange('postcode', result.postcode?.short_name || '');
        onChange('town', result.town?.short_name || '');
        onChange('country', result.country?.long_name || '');
        onChange('countryCode', result.country?.short_name || '');
        setAutoCompleteVal('');
    };

    return (
        <div className="sm-input postcode">
            <GooglePlacesAutocomplete
                value={autoCompleteVal}
                onChange={val => {
                    setError(null);
                    setAutoCompleteVal(val);
                }}
                onSelect={onPostCodeSelect}
                searchOptions={{
                    componentRestrictions: {
                        country: countryCode,
                    },
                }}
                onError={err =>
                    setError(
                        err === 'ZERO_RESULTS' ? 'No Results' : 'Unknown Error',
                    )
                }
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div>
                        <div className="input-container">
                            <i className="auto-complete-icon sm-input-prepend-icon fas fa-search fa-2x"></i>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search Postcode ...',
                                    className: `${classes} location-search-input`,
                                })}
                            />
                        </div>

                        <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer',
                                          borderBottom: '1px solid #ccc',
                                      }
                                    : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer',
                                          borderBottom: '1px solid #ccc',
                                      };

                                if (!suggestion.types.includes('postal_code'))
                                    return null;

                                return (
                                    <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </GooglePlacesAutocomplete>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

export default withFieldValidation(PostCodeAutoCompleteInput);
