import React from 'react';

import MoreDetailsFieldRoute from './MoreDetailsFieldRoute';
import Form from 'components/shared/form/presentational/Form';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const MoreDetails = ({ handleSubmit, isPosting, requirements }) => {
    const translate = useTranslate('internationalPayment')

    return (
        <div className="add-international-recipient-container">
            <Form
                submitText={translate('moreDetails.submit')}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                tracking
                trackingLabel="TRANSFERWISE_MORE_DETAILS_SUBMIT"
            >
                {requirements.map(({ fields }) =>
                    fields.map(({ group }) =>
                        group.map(field => (
                            <MoreDetailsFieldRoute
                                key={field.key}
                                field={field}
                            />
                        )),
                    ),
                )}
            </Form>
        </div>
    );
};

export default MoreDetails;
