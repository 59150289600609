import Typography from 'components/shared/generic/typography/presentational/Typography';
import React from 'react';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const PrivacyPolicy = () => {
    const translate = useTranslate('privacyPolicy');
    return (
        <div className="privacy-policy-container">
            <div className="terms-section">
                <Typography>{translate('textBody')}</Typography>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
