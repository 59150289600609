import React from 'react';
import {
    accountRoles,
    subscriptionMakeBalancesLookup,
} from 'suits-me-npm/constants';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import useGetLanguageBlock from 'suits-me-npm/languages/useGetLanguageBlock';

import Button from 'components/shared/generic/button/presentational/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'actions/modal';
import {
    EXCESSIVE_FUNDS_MODAL,
    REGRADE_SUBSCRIPTION_MODAL,
} from 'constants/shared/modalTypes';
import { getAccount } from 'suits-me-npm/selectors';
import { useRegradeSubscription } from 'suits-me-npm/hooks';

const roleNames = {
    [accountRoles.Essential]: 'essential',
    [accountRoles.Premium]: 'premium',
    [accountRoles.PremiumPlus]: 'premiumPlus',
    [accountRoles.EssentialEuro]: 'essential',
    [accountRoles.PremiumEuro]: 'premium',
    [accountRoles.PremiumPlusEuro]: 'premiumPlus',
};

const SubscriptionCard = ({ role, productType }) => {
    const dispatch = useDispatch();

    const roleName = roleNames[role];
    const translate = useTranslate('pricePlan');
    const getLanguageBlock = useGetLanguageBlock('pricePlan');
    const { clearedBalance } = useSelector(getAccount);
    const maxAmountAllowed = subscriptionMakeBalancesLookup[productType];
    const { isUpgrade } = useRegradeSubscription(productType, role);

    return (
        <div className="subscription-item" key={role}>
            <h2 className="title">{translate(`${roleName}.title`)}</h2>
            <div className="benefits-list">
                {getLanguageBlock(roleName).perks.map((benefit, i) => {
                    return (
                        <span className="benefit" key={i}>
                            <p className="benefit-title">{benefit.name}</p>
                            <p className="benefit-text">
                                {benefit.description}
                            </p>
                        </span>
                    );
                })}
            </div>

            {productType !== undefined && (
                <Button
                    disabled={productType === role}
                    onClick={e => {
                        if (clearedBalance > maxAmountAllowed) {
                            showExcessiveFundsModal(e);
                        } else {
                            showConfirmModal(e);
                        }
                    }}
                    tracking
                    trackingLabel={`${getLanguageBlock[roleName]}_SUBSCRIPTION_CLICK`}
                >
                    {translate(
                        productType === role
                            ? 'currentText'
                            : isUpgrade
                            ? 'upgrade'
                            : 'downgrade',
                    )}
                </Button>
            )}
        </div>
    );

    function showConfirmModal() {
        dispatch(
            showModal({
                type: REGRADE_SUBSCRIPTION_MODAL,
                props: {
                    currentRole: productType,
                    newRole: role,
                },
            }),
        );
    }

    function showExcessiveFundsModal() {
        dispatch(
            showModal({
                type: EXCESSIVE_FUNDS_MODAL,
            }),
        );
    }
};

export default SubscriptionCard;
