import React from 'react';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../containers/ModalOuterContainer';
import Button from 'components/shared/generic/button/presentational/Button';

const DismissModal = ({
    title = '',
    message = 'Are you sure?',
    handleCancel,
}) => {
    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description">
                {title !== '' && <Typography tag="h1">{title}</Typography>}
                <Typography>{message}</Typography>
            </div>
            <Button onClick={handleCancel}>Cancel</Button>
        </ModalOuterContainer>
    );
};

export default DismissModal;
