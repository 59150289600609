import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Box from 'components/shared/generic/box/presentational/Box';
import Form from 'components/shared/form/presentational/Form';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import CodeInput from 'components/shared/form/presentational/CodeInput';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const SetPasscode = ({
    securityCode,
    handleChange,
    handleSubmit,
    isPosting,
    error,
}) => {
    return (
        <Box className="set-passcode-container">
            <div className="statement-container">
                <SectionTitle title="Set Your Passcode" secondary />
                <div className="statement mb">
                    <Typography>
                        Please enter a 4-digit passcode for quick access next
                        time you log in to your online banking account.
                    </Typography>
                </div>
            </div>
            <Form
                className="set-passcode-form"
                submitText="Confirm"
                buttonSize="full"
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? 'Something went wrong! Please try again.' : ''}
            >
                <Field required>
                    <CodeInput
                        fields={4}
                        width={38}
                        height={52}
                        value={securityCode}
                        onChange={handleChange}
                        name="securityCode"
                        required
                    />
                </Field>
            </Form>
        </Box>
    );
};

export default SetPasscode;
