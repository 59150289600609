import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchPersonalDetails } from 'suits-me-npm/actions';
import {
    // getAccountsList, <-- For primary tax
    getPersonalDetails,
    getPersonalDetailsIsFetching,
} from 'suits-me-npm/selectors';
import { useEditPersonalDetails } from 'suits-me-npm/hooks';
import { MORE_PERSONAL_DETAILS } from 'constants/shared/routes';
import EditPersonalDetails from '../presentational/EditPersonalDetails';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { dispatchShowModal } from 'utils/modals';
import { SUCCESS_MODAL } from 'constants/shared/modalTypes';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const EditPersonalDetailsContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const details = useSelector(getPersonalDetails);
    const isFetching = useSelector(getPersonalDetailsIsFetching);
    const translate = useTranslate('personalDetails');
    // const accounts = useSelector(getAccountsList); <-- For primary tax

    const {
        formData,
        onChange,
        onSubmit,
        isPosting,
        error,
        handleSwitchClick,
    } = useEditPersonalDetails(handleSubmit);

    function handleSubmit() {
        dispatchShowModal(dispatch, SUCCESS_MODAL, {
            message: 'Personal Details Updated',
        });
        history.push(MORE_PERSONAL_DETAILS);
    }

    useEffect(() => {
        dispatch(fetchPersonalDetails());
    }, []);

    return (
        <PageContainer className="more-edit-personal-details-container">
            <SectionTitle
                title={translate('form.editPersonalDetails')}
                backRoute={MORE_PERSONAL_DETAILS}
            />
            <DataCheck
                data={details}
                isLoading={isFetching}
                noDataMessage={translate('noDataMessage')}
            >
                <EditPersonalDetails
                    data={details}
                    {...formData}
                    handleChange={onChange}
                    handleSubmit={onSubmit}
                    isPosting={isPosting}
                    error={error}
                    // showPrimaryTaxChange={accounts.length > 1} <-- For primary tax
                    handleSwitchClick={handleSwitchClick}
                />
            </DataCheck>
        </PageContainer>
    );
};

export default EditPersonalDetailsContainer;
