import React from 'react';
import { TW_ACCOUNT_STATES } from 'suits-me-npm/constants';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { PAYMENT_MAKE_PAYMENT } from 'constants/shared/routes';
import TransferWiseError from './TransferwiseError';
import TransferWiseLinkExisting from './TransferwiseLinkExisting';
import TransferWiseCreateAccount from './TransferwiseCreateAccount';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TransferWiseLink = ({ accountStatus, onCreateAccountClick }) => {
    const translate = useTranslate('internationalPayment');
    const { CONFLICT, ERROR, DOES_NOT_EXIST } = TW_ACCOUNT_STATES;

    return (
        <div className="transferwise-container">
            <SectionTitle
                title={translate('check.title')}
                backRoute={PAYMENT_MAKE_PAYMENT}
            />
            <div className="transferwise-body-wrapper">
                {accountStatus === DOES_NOT_EXIST && (
                    <TransferWiseCreateAccount
                        onCreateAccountClick={onCreateAccountClick}
                    />
                )}
                {accountStatus === CONFLICT && <TransferWiseLinkExisting />}
                {accountStatus === ERROR && <TransferWiseError />}
            </div>
        </div>
    );
};

export default TransferWiseLink;
