import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchPersonalDetails } from 'suits-me-npm/actions';
import {
    getPersonalDetails,
    getPersonalDetailsIsFetching,
    getPersonalDetailsError,
    getAccountsList,
} from 'suits-me-npm/selectors';
import { MORE, MORE_EDIT_PERSONAL_DETAILS } from 'constants/shared/routes';
import PersonalDetails from '../presentational/PersonalDetails';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const PersonalDetailsContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const details = useSelector(getPersonalDetails);
    const isFetching = useSelector(getPersonalDetailsIsFetching);
    const error = useSelector(getPersonalDetailsError);
    const translate = useTranslate('personalDetails');
    const accounts = useSelector(getAccountsList);

    const handleEditClick = () => history.push(MORE_EDIT_PERSONAL_DETAILS);

    useEffect(() => {
        dispatch(fetchPersonalDetails());
        //eslint-disable-next-line
    }, []);

    return (
        <PageContainer className="more-personal-details-container">
            <SectionTitle title={translate('titleText')} backRoute={MORE} />
            <DataCheck
                data={details}
                isLoading={isFetching}
                error={error}
                noDataMessage={translate('noDataMessage')}
            >
                <PersonalDetails
                    data={details}
                    editClick={handleEditClick}
                    showPrimaryTaxChange={accounts.length > 1}
                />
            </DataCheck>
        </PageContainer>
    );
};

export default PersonalDetailsContainer;
