import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { smUseAddCardHolder } from 'suits-me-npm/hooks';
import { getPersonalDetails } from 'suits-me-npm/selectors';
import AddCardHolderModal from '../presentational/AddCardHolderModal';

const AddCardHolderModalContainer = ({ hideModal }) => {
    const dispatch = useDispatch();
    const { form, handleChange, handleSubmit, isPosting } = smUseAddCardHolder(
        onSubmit,
    );
    const { country } = useSelector(getPersonalDetails);

    const handleClose = () => dispatch(hideModal());

    function onSubmit() {
        handleClose();
    }

    return (
        <AddCardHolderModal
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            country={country}
        />
    );
};

export default AddCardHolderModalContainer;
