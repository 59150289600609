import AdvertBlue1 from '_content/images/transactions/advert-blue-1.png';
import AdvertBlue2 from '_content/images/transactions/advert-blue-2.png';
import { MORE_CASHBACK_REWARDS, MORE_REFER_A_FRIEND } from './routes';

export default [
    {
        subtitle: 'Sign up for',
        title: 'Suitsme Rewards',
        background: AdvertBlue1,
        link: MORE_REFER_A_FRIEND,
    },
    {
        subtitle: 'Second special offer',
        title: 'Special reward',
        background: AdvertBlue2,
        link: MORE_CASHBACK_REWARDS,
    },
];
