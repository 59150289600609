import React from 'react';

const Container = ({
    className = '',
    fullHeight,
    flexCenter,
    flexColumn,
    flexJustifyCenter,
    flexAlignCenter,
    mini,
    children,
}) => {
    const classes = [];
    if (fullHeight) classes.push('full-height');
    if (flexCenter) classes.push('flex-center');
    if (flexColumn) classes.push('flex-column');
    if (flexJustifyCenter) classes.push('flex-justify-center');
    if (flexAlignCenter) classes.push('flex-align-center');
    if (mini) classes.push('mini-container');
    if (className) classes.push(className);

    return <div className={`container ${classes.join(' ')}`}>{children}</div>;
};

export default Container;
