import React from 'react';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import { useHistory } from 'react-router-dom';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuter from 'components/shared/modals/generic/presentational/ModalOuter';

const UpgradeToView = () => {
    const history = useHistory();
    const handleClose = () => history.push('/payments');
    return (
        <ModalOuter
            handleClose={handleClose}
            submitButtonText="Upgrade"
            handleSubmit={handleClose}
        >
            <SectionTitle title="A premium account is required for this feature" />
            <Typography tag="h2">
                Please upgrade to a premium account to unlock Direct Debits
            </Typography>
        </ModalOuter>
    );
};

export default UpgradeToView;
