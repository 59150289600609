import React from 'react';

import useTranslate from 'suits-me-npm/languages/useTranslate';

const NewCard = ({ onAddCardHolderClick }) => {
    const translate = useTranslate('cards.cardSlider');
    return (
        <div className="content new-card" onClick={onAddCardHolderClick}>
            <i className="icon fa fa-plus"></i>
            <p className="title">{translate('requestAdditionalCardHolder')}</p>
        </div>
    );
};

export default NewCard;
