//generics
export const ERROR_MODAL = 'ERROR_MODAL';
export const SUCCESS_MODAL = 'SUCCESS_MODAL';
export const PREMIUM_ACCOUNT_MODAL = 'PREMIUM_ACCOUNT_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const DISMISS_MODAL = 'DISMISS_MODAL';

//auth
export const ENTER_CVC_MODAL = 'ENTER_CVC_MODAL';
export const ADD_NEW_CARDHOLDER = 'ADD_NEW_CARDHOLDER';

export const STATUS_CARD_MODAL = 'STATUS_CARD_MODAL';

//payments
export const AUTHORIZE_PAYMENT_MODAL = 'AUTHORIZE_PAYMENT_MODAL';
export const SEARCH_AND_LIST_MODAL = 'SEARCH_AND_LIST_MODAL';
export const CONFIRM_AND_SEND_MODAL = 'CONFIRM_AND_SEND_MODAL';

// Subscription
export const REGRADE_SUBSCRIPTION_MODAL = 'REGRADE_SUBSCRIPTION_MODAL';
export const EXCESSIVE_FUNDS_MODAL = 'EXCESSIVE_FUNDS_MODAL';

// Statements
export const EXPORT_STATEMENT_MODAL = 'EXPORT_STATEMENT_MODAL';

//Account selection
export const ACCOUNT_SELECT = 'ACCOUNT_SELECT';
