import React from 'react';
import analytics from 'utils/analytics';

const Switch = ({
    name,
    value,
    children,
    onChange = () => {},
    tracking,
    trackingLabel = 'SWITCH_CLICK',
}) => {
    const handleChange = event => {
        onChange(event);
        if (tracking) {
            analytics.sendLinkEvent('SWITCH_CLICK', trackingLabel);
        }
    };

    return (
        <div className="switch-container">
            <label className="switch">
                <input
                    name={name}
                    checked={value}
                    type="checkbox"
                    onChange={handleChange}
                />
                <span className="slider" />
            </label>
            <div className="switch-content">{children}</div>
        </div>
    );
};

export default Switch;
