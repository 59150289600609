import React from 'react';

const ButtonContainer = ({ className = '', children, marginTop = '48px' }) => {
    return (
        <div
            className={`sm-button-container ${className}`}
            style={{ marginTop: marginTop }}
        >
            {children}
        </div>
    );
};

export default ButtonContainer;
