import React from 'react';
import { useSelector } from 'react-redux';
import { getAccount, getIsEuro } from 'suits-me-npm/selectors';
import { accountRoles } from 'suits-me-npm/constants';
import SubscriptionCard from './SubscriptionCard';

const ManageSubscription = () => {
    const { productType } = useSelector(getAccount) || {};
    const isEuro = useSelector(getIsEuro);

    return (
        <div className="subscriptions-list">
            {Object.values(accountRoles)
                .filter(item =>
                    isEuro ? item.includes('EUR') : item.includes('GBP'),
                )
                .map(role => (
                    <SubscriptionCard
                        key={role}
                        role={role}
                        productType={productType}
                    />
                ))}
        </div>
    );
};

export default ManageSubscription;
