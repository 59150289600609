import React from 'react';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const PaymentSuccess = ({ onSuccessClick, data }) => {
    const translate = useTranslate('internationalPayment')
    return (
        <div className="international-payment-status-container">
            <div className="status-icon">
                <i className="fas fa-check-circle"></i>
            </div>
            <div className="status-message">
                {translate('success.youSent')} {Number(data.targetAmount).toFixed(2)}{' '}
                {data.targetCurrency} {translate('success.to')} {data.recipient}
            </div>
            <Button onClick={onSuccessClick}>{translate('success.return')}</Button>
        </div>
    );
};

export default PaymentSuccess;
