import React from 'react';
import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';

const Button = ({
    children,
    type = 'button',
    className = '',
    isPosting,
    disabled,
    onClick = () => {},
    to,
    href,
    size = 'normal',
    tracking = false,
    trackingAction = 'BUTTON_CLICK',
    trackingLabel = 'BUTTON_CLICK',
    target = '_blank',
}) => {
    const handleClick = event => {
        onClick(event);
        if (tracking) {
            analytics.sendButtonEvent(trackingAction, trackingLabel);
        }
    };

    if (to) {
        return (
            <Link
                className={`sm-button ${size} ${className}`}
                type={type}
                onClick={handleClick}
                to={to}
                disabled={isPosting || disabled}
            >
                {isPosting ? (
                    <i className="far fa-spinner fa-spin" />
                ) : (
                    children
                )}
            </Link>
        );
    }

    if (href) {
        return (
            <a
                className={`sm-button ${size} ${className}`}
                type={type}
                onClick={handleClick}
                href={href}
                disabled={isPosting || disabled}
                target={target}
            >
                {isPosting ? (
                    <i className="far fa-spinner fa-spin" />
                ) : (
                    children
                )}
            </a>
        );
    }

    return (
        <button
            className={`sm-button ${size} ${className}`}
            type={type}
            onClick={handleClick}
            disabled={isPosting || disabled}
        >
            {isPosting ? <i className="far fa-spinner fa-spin" /> : children}
        </button>
    );
};

export default Button;
