import React from 'react';
import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';

const MenuItem = ({
    icon,
    title = 'Menu Item',
    small,
    href = '#0',
    to,
    target,
    onClick = () => {},
    tracking = false,
    trackingAction = 'LINK_CLICK',
    trackingLabel = 'LINK_CLICK',
}) => {
    const handleClick = event => {
        onClick(event);
        if (tracking) {
            const label = trackingLabel ?? (href || 'LINK_CLICK');
            analytics.sendLinkEvent(trackingAction, label);
        }
    };

    const classes = [];

    if (small) classes.push('small');

    if (to) {
        return (
            <Link
                onClick={onClick}
                to={to}
                className={`menu-item-container ${classes.join(' ')}`}
            >
                <div className="menu-item-content">
                    {icon && <span className="menu-item-icon">{icon}</span>}
                    {title && <p className="menu-item-title">{title}</p>}
                </div>
                <div className="menu-item-arrow">
                    <i className="far fa-caret-right" />
                </div>
            </Link>
        );
    }

    return (
        <a
            onClick={handleClick}
            href={href}
            target={target}
            className={`menu-item-container ${classes.join(' ')}`}
        >
            <div className="menu-item-content">
                {icon && <div className="menu-item-icon">{icon}</div>}
                {title && <p className="menu-item-title">{title}</p>}
            </div>
            <div className="menu-item-arrow">
                <i className="fa fa-caret-right" />
            </div>
        </a>
    );
};

export default MenuItem;
