import React from 'react';
import BulletIcon from '_content/images/icons/sm-bullet.svg';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import { formatSortCode } from 'suits-me-npm/utils';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import { useSelector } from 'react-redux';
import { getIsEuro } from 'suits-me-npm/selectors';

const AccountDetails = ({ data, flag }) => {
    const {
        sortCode,
        bankAccountNo,
        iban,
        accountName,
        clearedBalanceCurrencyCode,
    } = data;
    const translate = useTranslate('accountDetails');

    const isEuro = useSelector(getIsEuro);

    return (
        <>
            <div className="account-details-name">
                <Typography tag="h1">{accountName}</Typography>
                <div className="currency">
                    <img
                        src={flag.flag}
                        alt="country flag"
                        className="currency-flag currency-flag-gbp"
                    />
                    <Typography styles={{ fontWeight: 700 }}>
                        {clearedBalanceCurrencyCode}
                    </Typography>
                </div>
            </div>
            {isEuro ? (
                <div className="account-details">
                    <Typography tag="h1">IBAN</Typography>
                    <Typography>{iban}</Typography>
                </div>
            ) : (
                <>
                    <div className="account-details">
                        <Typography tag="h1">
                            {translate('accountNumber')}
                        </Typography>
                        <Typography>{bankAccountNo}</Typography>
                    </div>
                    <div className="account-details">
                        <Typography tag="h1">
                            {translate('sortCode')}
                        </Typography>
                        <Typography>{formatSortCode(sortCode)}</Typography>
                    </div>
                </>
            )}
            <div className="knowledge-section">
                <Typography tag="h3">
                    {translate('knowledgeSection.title')}
                </Typography>
                <ul>
                    <li>
                        <img src={BulletIcon} alt="bullet-list" />
                        {translate('knowledgeSection.bulletPoint1')}
                    </li>
                    <li>
                        <img src={BulletIcon} alt="bullet-list" />
                        {`${translate(
                            'knowledgeSection.bulletPoint2',
                        )} - ${translate(
                            'knowledgeSection.bulletPoint2Answer',
                        )}`}
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AccountDetails;
