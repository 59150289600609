import React from 'react'

const PageContainer = ({
    center,
    row,
    mini,
    extraMini,
    className,
    styles,
    children,
}) => {
    const classes = []
    if (center) classes.push('center')
    if (row) classes.push('row')
    if (mini) classes.push('mini')
    if (extraMini) classes.push('extraMini')
    if (className) classes.push(className)

    return (
        <div
            className={`sm-page-container ${classes.join(' ')}`}
            style={styles}
        >
            {children}
        </div>
    )
}

export default PageContainer
