import React from 'react';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import StandingOrderItem from './StandingOrderItem';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const StandingOrder = ({ data }) => {
    const translate = useTranslate('standingOrders');

    return (
        <div className="standing-order-container">
            <SectionTitle title={translate('active')} secondary />
            <div className="active-container">
                {data
                    .filter(({ isDeleted }) => !isDeleted)
                    .map((item, index) => (
                        <StandingOrderItem key={index} item={item} />
                    ))}
            </div>

            <SectionTitle title={translate('cancelled')} secondary />
            <div className="cancelled-container">
                {data
                    .filter(({ isDeleted }) => isDeleted)
                    .map((item, index) => (
                        <StandingOrderItem key={index} item={item} />
                    ))}
            </div>
        </div>
    );
};

export default StandingOrder;
