import React from 'react';
import dayjs from 'dayjs';
import { formattedCardNumber } from 'suits-me-npm/utils';

const EndedCard = ({ card }) => {
    const cardNumber = formattedCardNumber(card.cardPan);
    return (
        <div className="content frozen-card">
            <i className="icon fa fa-lock"></i>
            <p className="expiry-date">
                {dayjs(card.expiryDate).format('YY/MM')}
            </p>
            <span className="card-number">{cardNumber}</span>
            <p className="name">{card.cardHolderName}</p>
            <p className="title">Card Ended</p>
        </div>
    );
};

export default EndedCard;
