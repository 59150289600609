import React from 'react';

import Box from '../box/presentational/Box';
import Typography from '../typography/presentational/Typography';

const LoadingComponent = () => {
    return (
        <Box>
            <Typography className="data-check-loading loading-spinner">
                <i className="far fa-spinner fa-spin" />
            </Typography>
        </Box>
    );
};

export default LoadingComponent;
