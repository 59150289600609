import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPayee } from 'suits-me-npm/actions';
import { useForm, usePrevious } from 'suits-me-npm/utils';

const useCreatePayee = (postSuccess, isEuro) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const prevPostSuccess = usePrevious(postSuccess);

    const options = {
        payeeName: '',
        password: '',
        ...(isEuro ? { iban: '' } : { sortCode: '', payeeAccountNo: '' }),
    };

    const [formData, handleChange] = useForm(options);

    const handleSubmit = () => {
        const {
            payeeName,
            sortCode,
            payeeAccountNo,
            password,
            iban,
        } = formData;

        const postBody = {
            payeeName,
            password,
            ...(isEuro
                ? { iban }
                : {
                      sortCode: sortCode.replace(/-/g, ''),
                      payeeAccountNo: payeeAccountNo.replace(/\s/g, ''),
                  }),
        };

        dispatch(createPayee(postBody));
    };

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            history.goBack();
        }
    }, [postSuccess, prevPostSuccess]);

    return {
        formData,
        handleChange,
        handleSubmit,
    };
};

export default useCreatePayee;
