import React from 'react';

const Box = ({ row, center, className = '', styles, children, ...props }) => {
    const classes = [];
    if (row) classes.push('row');
    if (center) classes.push('center');
    if (className) classes.push(className);

    return (
        <div
            {...props}
            className={`sm-box ${classes.join(' ')}`}
            style={styles}
        >
            {children}
        </div>
    );
};

export default Box;
