import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { clearFieldErrors } from 'suits-me-npm/actions';
import { twUseCreateTransaction } from 'suits-me-npm/hooks';
import {
    TRANSFERWISE_SELECT_RECIPIENT,
    TRANSFERWISE_FINAL_REVIEW,
} from 'constants/shared/routes';
import useCheckTransferwiseQuote from 'utils/hooks/useCheckTransferwiseQuote';

import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import Container from 'components/shared/generic/container/presentational/Container';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import MoreDetails from '../presentational/MoreDetails';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import { isEmpty } from 'suits-me-npm/utils';

const MoreDetailsContainer = () => {
    const translate = useTranslate('internationalPayment');
    const dispatch = useDispatch();
    const history = useHistory();
    useCheckTransferwiseQuote();
    const [data] = twUseCreateTransaction();
    const {
        isPostingRequiements,
        requirements,
        requirementsError,
        setPostBody,
    } = data;

    const handleSubmit = () => {
        setPostBody();
        history.push(TRANSFERWISE_FINAL_REVIEW);
    };

    useEffect(() => {
        dispatch(clearFieldErrors());
        // eslint-disable-next-line
        if (!requirements || isEmpty(requirements)) {
            history.push(TRANSFERWISE_FINAL_REVIEW);
        }
    }, []);

    return (
        <Container>
            <SectionTitle
                title={translate('moreDetails.title')}
                backRoute={TRANSFERWISE_SELECT_RECIPIENT}
            />
            <DataCheck
                data={requirements}
                isLoading={isPostingRequiements}
                error={requirementsError}
                noDataMessage={translate('moreDetails.noDataMessage')}
                noDataClick={handleSubmit}
            >
                <MoreDetails
                    requirements={requirements}
                    handleSubmit={handleSubmit}
                    isPosting={isPostingRequiements}
                />
            </DataCheck>
        </Container>
    );
};

export default MoreDetailsContainer;
