import React from 'react';
import Container from 'components/shared/generic/container/presentational/Container';
import TransferWiseLinkContainer from '../../transferwiseLink/containers/TransferwiseLinkContainer';
import { twLoadingStates, twLoadingMessageStatusTranslate } from 'constants/shared/transferwise';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const CheckInternationalUser = ({ accountStatus }) => {
    const translate = useTranslate('internationalPayment')

    return (
        <Container>
            {twLoadingStates.includes(accountStatus) && (
                <p className="data-check-loading loading-spinner">
                    <i className="far fa-spinner fa-spin" />
                    {translate(`loadingStatus.${twLoadingMessageStatusTranslate[accountStatus]}`)}
                </p>
            )}

            {!twLoadingStates.includes(accountStatus) && (
                <TransferWiseLinkContainer accountStatus={accountStatus} />
            )}
        </Container>
    );
};

export default CheckInternationalUser;
