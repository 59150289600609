import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleStandingOrder } from 'suits-me-npm/selectors';
import { deleteStandingOrder, fetchStandingOrders } from 'suits-me-npm/actions';
import { CONFIRM_MODAL } from 'constants/shared/modalTypes';
import { hideModal, showModal } from 'actions/modal';
import {
    getStandingOrderError,
    getStandingOrderIsFetching,
    getStandingOrders,
} from 'suits-me-npm/selectors';
import { formatMoney, isEmpty } from 'suits-me-npm/utils';
import { PAYMENT_STANDING_ORDER } from 'constants/shared/routes';
import StandingOrderItem from '../presentational/SingleStandingOrder';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const StandingOrderItemContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const standingOrders = useSelector(getStandingOrders);
    const isFetching = useSelector(getStandingOrderIsFetching);
    const error = useSelector(getStandingOrderError);
    const standingOrder = useSelector(state =>
        getSingleStandingOrder(state, id),
    );
    const translate = useTranslate('standingOrders.deleteStandingOrderModal');

    const handleDeleteModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    error,
                    title: translate('titleText'),
                    message: translate('descriptionText'),
                    handleSubmit: () => {
                        dispatch(deleteStandingOrder(id)).then(() => {
                            history.push(PAYMENT_STANDING_ORDER);
                            dispatch(hideModal());
                        });
                    },
                },
            }),
        );
    };

    useEffect(() => {
        if (isEmpty(standingOrders)) {
            dispatch(fetchStandingOrders());
        }
    }, []);

    return (
        <>
            <SectionTitle
                title={
                    standingOrder ? (
                        `${standingOrder.bankAccountNo} - £${formatMoney(
                            standingOrder.amount,
                        )}`
                    ) : (
                        <i className="far fa-spinner fa-spin" />
                    )
                }
                backRoute={PAYMENT_STANDING_ORDER}
            />
            <DataCheck
                data={standingOrders}
                isLoading={isFetching}
                error={error}
                noDataMessage="This standing order could not be found."
            >
                <StandingOrderItem
                    data={standingOrder}
                    handleDelete={handleDeleteModal}
                />
            </DataCheck>
        </>
    );
};

export default StandingOrderItemContainer;
