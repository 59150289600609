import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { twCheckAccountExists } from 'suits-me-npm/actions';
import {
    PAYMENT_MAKE_PAYMENT,
    TRANSFERWISE_CHECK,
} from 'constants/shared/routes';
import useStoredQuote from 'utils/hooks/useStoredQuote';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import InitialQuote from '../presentational/InitialQuote';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const InitialQuoteContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setStoredQuote } = useStoredQuote();

    const handleContinueClick = (_event, sourceAmount, targetCurrency) => {
        history.push(TRANSFERWISE_CHECK);
        setStoredQuote({ sourceAmount, targetCurrency });
    };

    useEffect(() => {
        dispatch(twCheckAccountExists());
        // eslint-disable-next-line
    }, []);

    const translate = useTranslate('internationalPayment');

    return (
        <PageContainer>
            <SectionTitle
                title={translate('initialQuoteTitle')}
                backRoute={PAYMENT_MAKE_PAYMENT}
            />
            <InitialQuote continueClick={handleContinueClick} />
        </PageContainer>
    );
};

export default InitialQuoteContainer;
