import React from 'react';
import { isEmpty } from 'suits-me-npm/utils';

const ComponentDataCheck = ({ children, data, isLoading, noData = 'n/a' }) => {
    if (isLoading) {
        return (
            <span className="component-data-check-loading loading-spinner">
                <i className="far fa-spinner fa-spin" />
            </span>
        );
    }

    if (isEmpty(data)) {
        return <span className="component-data-check-no-data">{noData}</span>;
    }

    return <span className="component-data">{children}</span>;
};

export default ComponentDataCheck;
