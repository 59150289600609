import React from 'react';
import { useDispatch } from 'react-redux';
import { activateCard } from 'suits-me-npm/actions';

import Button from 'components/shared/generic/button/presentational/Button';
import { hideModal, showModal } from 'actions/modal';
import { CONFIRM_MODAL } from 'constants/shared/modalTypes';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const IssuedCard = ({ position, card }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cards');

    return (
        <div className="content issued-card">
            <i className="icon fa fa-lock"></i>
            <p className="title">{translate('cardSlider.cardNotActivated')}</p>
            {position === 'center' && (
                <Button onClick={showActivateModal}>{translate('cardSlider.activateCard')}</Button>
            )}
        </div>
    );

    function showActivateModal(e) {
        e.preventDefault();
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    title: translate('activateCardModal.titleText'),
                    message: translate('activateCardModal.descriptionText'),
                    handleSubmit: activate,
                },
            }),
        );
    }

    function activate(e) {
        e.preventDefault();
        dispatch(hideModal());
        dispatch(activateCard(card.cardSerial));
    }
};

export default IssuedCard;
