import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    getTwQuote,
    getTwRecipientsError,
    getTwRecipientRequirements,
} from 'suits-me-npm/selectors';
import { twUseAddRecipient } from 'suits-me-npm/hooks';
import {
    twFetchRecipients,
    twCreateRecipientResetForm,
} from 'suits-me-npm/actions';
import { isEmpty } from 'suits-me-npm/utils';
import useCheckTransferwiseQuote from 'utils/hooks/useCheckTransferwiseQuote';
import {
    PAYMENT_MAKE_PAYMENT,
    TRANSFERWISE_MORE_DETAILS,
    TRANSFERWISE_SELECT_RECIPIENT,
} from 'constants/shared/routes';

import Container from 'components/shared/generic/container/presentational/Container';
import AddNewRecipient from '../presentational/AddNewRecipient';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const AddNewRecipientContainer = () => {
    const translate = useTranslate('internationalPayment');
    const history = useHistory();
    useCheckTransferwiseQuote();
    const requirementsError = useSelector(getTwRecipientsError);
    const requirements = useSelector(getTwRecipientRequirements);
    const quote = useSelector(getTwQuote);

    const dispatch = useDispatch();
    const [data, handleChange, handleSubmit] = twUseAddRecipient();

    const {
        staticForm: { accountHolderName, email, type },
        success,
        isPosting,
        error,
        typeOptions,
        usageInfo,
        fields,
        isFetching,
    } = data;

    const handleResetForm = useCallback(() => {
        dispatch(twCreateRecipientResetForm());
    }, [dispatch]);

    useEffect(() => {
        if (isEmpty(quote)) {
            history.push(PAYMENT_MAKE_PAYMENT);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleResetForm();
        // eslint-disable-next-line
    }, [handleResetForm]);

    useEffect(() => {
        if (success) {
            dispatch(twFetchRecipients(quote.targetCurrency));
            history.push(TRANSFERWISE_MORE_DETAILS);
        }
        // eslint-disable-next-line
    }, [success]);

    return (
        <Container>
            <SectionTitle
                title={translate('addNewRecipient.title')}
                backRoute={TRANSFERWISE_SELECT_RECIPIENT}
            />
            <DataCheck
                isLoading={isFetching}
                error={(!data || isEmpty(data)) && (requirementsError || error)}
                data={requirements}
                noDataMessage={translate('addNewRecipient.noDataMessage')}
            >
                <AddNewRecipient
                    error={error}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    typeOptions={typeOptions}
                    usageInfo={usageInfo}
                    fields={fields}
                    accountHolderName={accountHolderName}
                    email={email}
                    type={type}
                    isPosting={isPosting}
                    data={data}
                />
            </DataCheck>
        </Container>
    );
};

export default AddNewRecipientContainer;
