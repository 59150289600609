/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFieldError, removeFieldError } from 'suits-me-npm/actions';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import { getFieldError, getFieldErrorsVisible } from 'suits-me-npm/selectors';
import { isEmpty, usePrevious } from 'suits-me-npm/utils';

const withFieldValidation = WrappedComponent => props => {
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const error = useSelector(state => getFieldError(state, props));
    const errorsVisible = useSelector(getFieldErrorsVisible);
    const prevValue = usePrevious(props.value);
    const prevErrorsVisible = usePrevious(errorsVisible);
    const translate = useTranslate('withFieldValidation');

    useEffect(() => {
        _validate(props.value);

        return () => {
            dispatch(removeFieldError(props.name));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(prevValue) && !isEmpty(props.value)) {
            _validate(props.value);
        }
        if (!prevErrorsVisible && errorsVisible) {
            handleShowError();
        }
    });

    return (
        <>
            <WrappedComponent
                {...props}
                showError={handleShowError}
                hideError={handleHideError}
                onChange={handleChange}
            />
            {!!showError && !!error && (
                <p className="error red-text text-accent-4">{error}</p>
            )}
        </>
    );

    function handleShowError() {
        if (!showError) {
            setShowError(true);
        }
    }

    function handleHideError() {
        if (showError) {
            setShowError(false);
        }
    }

    function handleChange(name, value, ...rest) {
        _validate(value);
        props.onChange(name, value, ...rest);
    }

    function _validate(value) {
        const {
            name,
            required,
            validate = () => {},
            validationRegexp,
            maxLength,
            minLength,
        } = props;

        const customError = validate(value);

        if (required && isEmpty(value)) {
            dispatch(addFieldError({ name, value: translate('required') }));
        } else if (
            value &&
            validationRegexp &&
            !RegExp(validationRegexp).test(value)
        ) {
            dispatch(addFieldError({ name, value: translate('invalidValue') }));
        } else if (customError && customError.length) {
            dispatch(addFieldError({ name, value: customError }));
        } else if (maxLength && value && value.length > maxLength) {
            dispatch(
                addFieldError({
                    name,
                    value: `${translate('shorterThan')} ${maxLength}.`,
                }),
            );
        } else if (minLength && value && value.length < minLength) {
            dispatch(
                addFieldError({
                    name,
                    value: `${translate('longerThan')} ${minLength}.`,
                }),
            );
        } else if (error) {
            dispatch(removeFieldError(name));
        }
    }
};

export default withFieldValidation;
