import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

const Radio = ({ options, onChange, name, value, showError }) => {
    return <div className="checkbox-list size-lg-12">{options && options.map(renderButton)}</div>;

    function renderButton({ text, value: curVal }) {
        return (
            <div className="radio-button">
                <input
                    type="radio"
                    name={name}
                    id={curVal}
                    value={curVal}
                    checked={curVal === value}
                    onChange={() => handleChange(curVal)}
                />
                <div className={'holder'}>
                    <label className="text" htmlFor={value}>
                        {text}
                    </label>
                    <span className="outer">
                        <span className="inner" />
                    </span>
                </div>
            </div>
        );
    }

    function handleChange(val) {
        onChange(name, val);
        showError();
    }
};

export default withFieldValidation(Radio);
