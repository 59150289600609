import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSelectedPayee,
    getStandingOrderIsPosting,
} from 'suits-me-npm/selectors';
import { useMakeStandingOrder } from 'suits-me-npm/hooks';
import {
    PAYMENT_STANDING_ORDER_SUCCESS,
    PAYMENT_STANDING_ORDER,
    PAYMENT_STANDING_ORDER_PAYEE,
} from 'constants/shared/routes';
import { showModal } from 'actions/modal';
import { CONFIRM_AND_SEND_MODAL } from 'constants/shared/modalTypes';
import { dispatchHideModal } from 'utils/modals';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import StandingOrderForm from '../presentational/StandingOrderForm';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';

const StandingOrderFormContainer = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedPayee = useSelector(getSelectedPayee);
    const {
        form,
        fields,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    } = useMakeStandingOrder(onSubmit);

    const handleConfirmModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_AND_SEND_MODAL,
                props: {
                    form,
                    selectedPayee,
                    postingSelector: getStandingOrderIsPosting,
                    error,
                    type: 'standing-order',
                    handleSubmit,
                },
            }),
        );
    };

    function onSubmit() {
        dispatchHideModal(dispatch);
        history.push(PAYMENT_STANDING_ORDER_SUCCESS, {
            data: {
                amount: form.Amount,
                recipient: selectedPayee.payeeName,
            },
        });
    }

    useEffect(() => {
        if (!selectedPayee) {
            history.push(PAYMENT_STANDING_ORDER);
        }
    }, []);

    return (
        <PageContainer className="standing-order-form-container">
            <SectionTitle
                title={selectedPayee && selectedPayee.payeeName}
                backRoute={PAYMENT_STANDING_ORDER_PAYEE}
                additionalText={`${selectedPayee &&
                    selectedPayee.accountNo} | ${selectedPayee &&
                    selectedPayee.sortCode}`}
            />
            <StandingOrderForm
                {...form}
                fields={fields}
                handleChange={handleChange}
                handleSubmit={handleConfirmModal}
                isPosting={isPosting}
            />
        </PageContainer>
    );
};

export default StandingOrderFormContainer;
