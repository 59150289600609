import React from 'react';

import { PAYMENT_MAKE_PAYMENT } from 'constants/shared/routes';

import InternationalQuoteCalculatorContainer from 'components/shared/generic/internationalQuoteCalculator/containers/InternationalQuoteCalculatorContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ReviewQuote = ({ onContinueClick, storedQuote }) => {
    const translate = useTranslate('internationalPayment')
    return (
        <div className="review-quote-container">
            <SectionTitle
                title={translate('reviewQuote.title')}
                backRoute={PAYMENT_MAKE_PAYMENT}
                backState={{ international: true }}
            />
            <InternationalQuoteCalculatorContainer
                buttonText={translate('reviewQuote.confirmQuote')}
                temporary={false}
                onContinueClick={onContinueClick}
                storedQuote={storedQuote}
            />
        </div>
    );
};

export default ReviewQuote;
