import { useEffect, useCallback, useRef } from 'react';

export default callback => {
    const ref = useRef(null);
    const page = useRef(1);

    const loadMore = useCallback(
        ([entry]) => {
            if (entry.isIntersecting) {
                page.current = page.current + 1;
                callback(page.current);
            }
        },
        [callback],
    );

    useEffect(() => {
        const observer = new IntersectionObserver(loadMore, {
            root: null,
            rootMargin: '0px',
            threshold: 0.25,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, loadMore]);

    return ref;
};
