import React from 'react';
import { TW_REDIRECT_URI, TW_CLIENT_ID, TW_AUTH_LINK_URL } from 'config';
import {
    TRANSFERWISE_CREATE_ACCOUNT,
    TRANSFERWISE_PRIVACY_POLICY,
} from 'constants/shared/routes';
import TransferwiseLogo from '_content/images/icons/transferwise/transferwise-logo.png';
import Button from 'components/shared/generic/button/presentational/Button';
import analytics from 'utils/analytics';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const TransferWiseCreateAccount = ({ onCreateAccountClick }) => {
    const translate = useTranslate('internationalPayment')

    return (
        <div className="transferwise-create-account">
            <div className="transferwise-logo-wrapper">
                <img
                    src={TransferwiseLogo}
                    alt="transferwise-logo"
                    className="transferwise-logo"
                />
            </div>

            <div className="subtitle-wrapper">
                <h3>{translate('check.weUseTransferWise')}</h3>
            </div>

            <div className="info-slug-wrapper">
                <div className="info-slug">
                    <i className="far fa-thumbs-up blue" />
                    <p>{translate('check.realExchangeRate')}</p>
                </div>
                <div className="info-slug">
                    <i className="far fa-thumbs-up blue" />
                    <p>{translate('check.clearUpfrontFees')}</p>
                </div>
            </div>

            <div className="terms-links-wrapper">
                <p>
                {translate('check.byCreatingAccount')}{' '}
                    <a
                        href={TRANSFERWISE_CREATE_ACCOUNT}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            analytics.sendLinkEvent(
                                'LINK_CLICK',
                                'TRANSFERWISE_CREATE_ACCOUNT_LOGIN',
                            )
                        }
                    >
                        {translate('check.termsOfService')}
                    </a>{' '}
                    {translate('check.and')}{' '}
                    <a
                        href={TRANSFERWISE_PRIVACY_POLICY}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            analytics.sendLinkEvent(
                                'LINK_CLICK',
                                'TRANSFERWISE_PRIVACY_PRIVACY',
                            )
                        }
                    >
                        {translate('check.privacyPolicy')}
                    </a>
                </p>
                <Button
                    onClick={onCreateAccountClick}
                    tracking
                    trackingLabel="TRANSFERWISE_CREATE_ACCOUNT_LOGIN"
                    size="small"
                >
                    {translate('check.createAccountForMe')}
                </Button>
                <p>
                {translate('check.alreadyUse')}{' '}
                    <a
                        href={`${TW_AUTH_LINK_URL}/?client_id=${TW_CLIENT_ID}&redirect_uri=${TW_REDIRECT_URI}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            analytics.sendLinkEvent(
                                'LINK_CLICK',
                                'TRANSFERWISE_LOGIN_CLICK',
                            )
                        }
                    >
                        {translate('check.logIn')}
                    </a>
                </p>
            </div>
        </div>
    );
};

export default TransferWiseCreateAccount;
