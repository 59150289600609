import React from 'react';
import { TRANSFERWISE_INITIAL_QUOTE } from 'constants/shared/routes';
import Button from 'components/shared/generic/button/presentational/Button';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const InternationalPayees = () => {
    const translate = useTranslate('internationalPayment') 
    return (
        <>
            <Typography>
                {translate('introText')}
            </Typography>
            <Button className="center" to={TRANSFERWISE_INITIAL_QUOTE}>
                {translate('proceed')}
            </Button>
        </>
    );
};

export default InternationalPayees;
