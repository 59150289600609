import { useState, useEffect, useCallback } from 'react';

export default onSearchChange => {
    const [searchTerm, setSearchTerm] = useState('');
    const [displayedList, setDisplayedList] = useState([]);

    const handleSearchTerm = useCallback(
        ({ target: { value } }) => {
            setSearchTerm(value);
        },
        [setSearchTerm],
    );

    useEffect(() => {
        if (searchTerm) {
            if (!onSearchChange) return;

            const listResult = onSearchChange(searchTerm);
            setDisplayedList(listResult);
        }
        // eslint-disable-next-line
    }, [searchTerm]);

    return {
        searchTerm,
        displayedList,
        handleSearchTerm,
    };
};
