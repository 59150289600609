import { useEffect, useState } from 'react';
import { CAPTCHA_SITE_KEY } from 'config';

const useRecaptcha = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadRecaptcha();
        return () => {
            const el = document.getElementById('recaptcha-key');
            el?.remove(); 
            const badge = document.getElementsByClassName('grecaptcha-badge').item(0);
            badge?.remove();
        }
    }, []);

    const getToken = (action = 'submit') => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(CAPTCHA_SITE_KEY, { action })
                    .then(token => {
                        setLoading(false);
                        resolve(token)
                    })
                    .catch(err => {
                        setLoading(false);
                        reject(err);
                    });
            });
        })
    };

    return [getToken, loading]
};

const loadRecaptcha = (id, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`;
        script.id = 'recaptcha-key';
        script.onload = function() {
            if (callback) callback();
        };
        document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
};

export default useRecaptcha;
