import React from 'react';

import Button from 'components/shared/generic/button/presentational/Button';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Container from 'components/shared/generic/container/presentational/Container';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import BulletPoint from 'components/shared/generic/bulletPoint/presentational/BulletPoint';

const AddCash = () => {
    const translate = useTranslate('addCash');
    return (
        <Container>
            <div className="payments-container">
                <SectionTitle
                    title={translate('titleText')}
                    backRoute="/payments"
                />
                <SectionTitle title={translate('subTitle')} secondary />

                <div className="add-cash-steps">
                    <p>{translate('stepOne')}</p>
                    <p>{translate('stepTwo')}</p>
                    <p>{translate('stepThree')}</p>
                    <p>{translate('stepFour')}</p>
                </div>

                <div className="add-cash-faqs-container">
                    <SectionTitle title={translate('faqTitle')} secondary />

                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'bulletOne.question',
                        )}</strong> - ${translate('bulletOne.answer')}.`}
                    />

                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'bulletTwo.question',
                        )}</strong> - ${translate('bulletTwo.answer')}.`}
                    />

                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'bulletThree.question',
                        )}</strong> - ${translate('bulletThree.answer')}.`}
                    />

                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'bulletFour.question',
                        )}</strong> - ${translate('bulletFour.answer')}.`}
                    />

                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'bulletFive.question',
                        )}</strong> - ${translate('bulletFive.answer')}
                        `}
                    />
                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'fees.premium',
                        )}</strong> ${translate('fees.premiumPrice')}
                        `}
                        dash
                    />
                    <BulletPoint
                        html
                        leftText={`<strong>${translate(
                            'fees.premiumPlus',
                        )}</strong> ${translate('fees.premiumPlusPrice')}
                        `}
                        dash
                    />
                </div>

                <ButtonContainer marginTop="20px">
                    <Button
                        className="center"
                        href="https://consumer.paypoint.com/"
                    >
                        {translate('buttonText')}
                    </Button>
                </ButtonContainer>
            </div>
        </Container>
    );
};

export default AddCash;
