import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

const TabSelect = ({ options, value, onChange, name, showError }) => {
    return <div className="tab-select-container">{options.map(renderTab)}</div>;

    function renderTab(opt) {
        const active = opt.value === value;

        return (
            <button
                type="button"
                className={`tab-select-item ${active ? 'active' : ''}`}
                key={opt.value}
                onClick={() => handleChange(opt.value)}
                disabled={active}
            >
                {opt.text}
            </button>
        );
    }

    function handleChange(val) {
        onChange(name, val);
        showError();
    }
};

export default withFieldValidation(TabSelect);
