import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearFieldErrors,
    setIsEuro,
    setLanguageCode,
} from 'suits-me-npm/actions';
import useGoogleAnalytics from 'utils/hooks/useGoogleAnalytics';
import AppRoutes from 'components/routes';
import ModalRoute from 'components/shared/modals/generic/containers/ModalRoot';
import GlobalRedirect from 'components/shared/generic/GlobalRedirect';
import HeaderContainer from 'components/shared/layout/header/containers/HeaderContainer';
import useIsPrivateRoute from 'components/routes/hocs/useIsPrivateRoute';

import AuthorizedHeader from 'components/shared/layout/authorizedHeader';
import { toggleTheme } from 'actions/isDarkModeEnabled';
import { getTheme } from 'selectors/darkMode';
import HelpButton from 'components/shared/layout/helpButton/HelpButton';

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const isPrivateRoute = useIsPrivateRoute();
    const isDarkModeEnabled = useSelector(getTheme);

    const token = localStorage.getItem('token');

    useGoogleAnalytics();

    useEffect(() => {
        checkIsEuro();
    }, [token]);

    useEffect(() => {
        checkTheme();
        checkLanguage();

        return history.listen(() => {
            dispatch(clearFieldErrors());
        });
    }, []);

    return (
        <div className="app">
            <div id="page-container">
                {isPrivateRoute ? <AuthorizedHeader /> : <HeaderContainer />}
                <HelpButton />

                <div id="page-routes">
                    <div
                        className={`page-body ${
                            isPrivateRoute ? 'authed' : ''
                        } ${isDarkModeEnabled ? 'dark-mode' : ''}`}
                    >
                        <AppRoutes />
                        <ModalRoute />
                        <GlobalRedirect />
                    </div>
                </div>
            </div>
        </div>
    );

    function checkTheme() {
        const item =
            window.localStorage.getItem('isDarkModeEnabled') === 'true';

        dispatch(toggleTheme(item));
    }

    function checkLanguage() {
        const item = JSON.parse(window.localStorage.getItem('languageCode'));

        if (item) {
            dispatch(setLanguageCode(item));
        }
    }

    function checkIsEuro() {
        if (token && token !== 'null') {
            dispatch(setIsEuro(token));
        }
    }
}

export default App;
