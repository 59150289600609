import React from 'react';

import SwitchWith404 from './SwitchWith404';
import { Route } from 'react-router-dom';
import SubRouter from './SubRouter';
import withAuth from 'components/shared/auth/hocs/withAuth';

const RootStack = ({ stack }) => {
    return (
        <SwitchWith404>
            {stack.map(item => (
                <Route
                    key={item.base}
                    path={item.base}
                    component={getStack(item)}
                />
            ))}
        </SwitchWith404>
    );

    function getStack({ base, stack, auth }) {
        let Stack = props => (
            <SubRouter base={base} routes={stack} {...props} />
        );
        if (auth) Stack = withAuth(Stack);
        return Stack;
    }
};

export default RootStack;
