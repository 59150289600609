import React from 'react';
import useInternationalQuote from 'utils/hooks/useInternationalQuote';
import InternationalQuoteCalculator from '../presentational/InternationalQuoteCalculator';
import Container from 'components/shared/generic/container/presentational/Container';

const InternationalQuoteCalculatorContainer = ({
    onContinueClick,
    buttonText,
    temporary,
    storedQuote,
}) => {
    const {
        quote,
        quoteIsFetching,
        targetCurrency,
        sourceAmount,
        handleSourceAmount,
        handleShowModal,
    } = useInternationalQuote(temporary, storedQuote);

    return (
        <Container>
            <InternationalQuoteCalculator
                handleShowModal={handleShowModal}
                sourceAmount={sourceAmount}
                handleSourceAmount={handleSourceAmount}
                targetCurrency={targetCurrency}
                tempQuoteIsFetching={quoteIsFetching}
                tempQuote={quote}
                buttonText={buttonText}
                onContinueClick={onContinueClick}
            />
        </Container>
    );
};

export default InternationalQuoteCalculatorContainer;
