import React from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN } from 'constants/shared/routes';
import LoginTemplate from 'components/shared/layout/loginTemplate/presentational/LoginTemplate';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Button from 'components/shared/generic/button/presentational/Button';

const RecoverySuccess = () => {
    const history = useHistory();

    return (
        <LoginTemplate>
            <div className="recovery-success-container">
                <div className="statement-container">
                    <SectionTitle
                        title="An email has been sent to your inbox"
                        secondary
                    />
                    <div className="statement">
                        <Typography>
                            An email will be delivered to your inbox with
                            details highlighting the the next steps in resetting
                            your forgotten credentials.
                        </Typography>
                    </div>
                    <Button onClick={() => history.push(LOGIN)}>
                        Back to Login
                    </Button>
                </div>
            </div>
        </LoginTemplate>
    );
};

export default RecoverySuccess;
