import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import StandingOrderContainer from '../../standingOrders/containers/StandingOrderContainer';
import StandingOrderItemContainer from '../../singleStandingOrder/containers/SingleStandingOrderContainer';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';

const MainContainer = () => {
    const match = useRouteMatch('/payments/standing-order/:id');

    return (
        <PageContainer>
            {match ? (
                <StandingOrderItemContainer />
            ) : (
                <StandingOrderContainer />
            )}
        </PageContainer>
    );
};

export default MainContainer;
