import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from 'suits-me-npm/selectors';
import { showModal } from 'actions/modal';
import { PREMIUM_ACCOUNT_MODAL } from 'constants/shared/modalTypes';
import {
    PAYMENT_MAKE_PAYMENT,
    // PAYMENT_SEND_PAYMENT,
    PAYMENT_STANDING_ORDER,
    PAYMENT_DIRECT_DEBIT,
    PAYMENT_MANAGE_PAYEES,
    PAYMENT_ADD_CASH,
    MORE_ACCOUNT_DETAILS,
} from 'constants/shared/routes';
import Container from 'components/shared/generic/container/presentational/Container';
import Payments from '../presentational/Payments';
import { accountRoles } from 'suits-me-npm/constants';
import { getTheme } from 'selectors/darkMode';

const PaymentsContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const accountState = useSelector(getAccount);
    const isDarkModeEnabled = useSelector(getTheme);

    const handleModal = () =>
        dispatch(
            showModal({
                type: PREMIUM_ACCOUNT_MODAL,
                props: { feature: 'direct debits' },
            }),
        );
    const handleMakePayment = () => history.push(PAYMENT_MAKE_PAYMENT);
    const handleViewStandingOrders = () => history.push(PAYMENT_STANDING_ORDER);
    const handleManagePayee = () => history.push(PAYMENT_MANAGE_PAYEES);
    const handleViewDirectDebits = () =>
        accountState.productType !== accountRoles.Essential
            ? history.push(PAYMENT_DIRECT_DEBIT)
            : handleModal();
    const handleAddCash = () => history.push(PAYMENT_ADD_CASH);
    const handleSalary = () => history.push(MORE_ACCOUNT_DETAILS);

    return (
        <Container>
            <Payments
                onMakePaymentClick={handleMakePayment}
                onManagePayeeClick={handleManagePayee}
                onStandingOrdersClick={handleViewStandingOrders}
                onDirectDebitClick={handleViewDirectDebits}
                onAddCashClick={handleAddCash}
                onSalaryClick={handleSalary}
                isDarkModeEnabled={isDarkModeEnabled}
            />
        </Container>
    );
};

export default PaymentsContainer;
