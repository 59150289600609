/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

const InfiniteScroll = ({ className, last, size, children }, ref) => {
    const classes = [];
    if (size) classes.push(size);
    if (className) classes.push(className);

    return (
        <div className={`sm-infinite-scroll ${classes.join(' ')}`}>
            {children}
            {!last && (
                <h1 ref={ref} className="loading-more">
                    <i className="far fa-spinner fa-spin" />
                </h1>
            )}
        </div>
    );
};

export default forwardRef(InfiniteScroll);
