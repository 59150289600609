import React from 'react';
import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';

const Adverts = ({ list }) => {
    const isDisabled = list.length <= 2;

    return (
        <div className="adverts-container">
            <div className="adverts-grid">
                {list.map(({ title, background, link }, index) => (
                    <Link
                        key={index}
                        to={{ pathname: link, state: { backRoute: '/' } }}
                    >
                        <img
                            className="item"
                            src={background}
                            alt={`Suits Me Advert ${index + 1}`}
                            onClick={() =>
                                analytics.sendLinkEvent('Adverts Click', title)
                            }
                        />
                    </Link>
                ))}
                <span
                    className={`adverts-arrow left ${isDisabled && 'disabled'}`}
                >
                    <i className="caret fas fa-caret-left" />
                </span>
                <span
                    className={`adverts-arrow right ${isDisabled &&
                        'disabled'}`}
                >
                    <i className="caret fas fa-caret-right" />
                </span>
            </div>
        </div>
    );
};

export default Adverts;
