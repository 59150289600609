import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import useCardCarousel from 'utils/hooks/useCardCarousel';
import { Link } from 'react-router-dom';

import CarouselContainer from '../../carousel/containers/CarouselContainer';
import CarouselButtonsContainer from '../../carouselButtons/containers/CarouselButtonsContainer';

import { getCards } from 'suits-me-npm/selectors';
import { showModal } from 'actions/modal';
import { getCardsIsFetching } from 'suits-me-npm/selectors';

import { newCardFees } from 'suits-me-npm/constants/fees';
import { formatMoney } from 'suits-me-npm/utils';
import {
    ENTER_CVC_MODAL,
    ADD_NEW_CARDHOLDER,
    ERROR_MODAL,
} from 'constants/shared/modalTypes';
import LoadingComponent from 'components/shared/generic/loader/LoadingComponent';

const CardsCarouselContainer = ({ account }) => {
    const dispatch = useDispatch();
    const cards = useSelector(getCards);
    const isFetching = useSelector(getCardsIsFetching);
    const isPremiumPlus = account.productType.includes('PREMP');
    const translate = useTranslate('cards');

    const {
        carouselCards,
        currentCard,
        handleDirection,
        isStart,
        isEnd,
    } = useCardCarousel(cards);

    const onNextClick = () => handleDirection(true);
    const onPrevClick = () => handleDirection(false);

    const handleEnterCVC = () =>
        dispatch(
            showModal({
                type: ENTER_CVC_MODAL,
                props: { cardSerial: currentCard.cardSerial },
            }),
        );

    const handleAddNewCardHolder = () => {
        const newCardFee = newCardFees[account.productType];

        if (newCardFee <= account.clearedBalance) {
            dispatch(showModal({ type: ADD_NEW_CARDHOLDER, props: {} }));
        } else {
            dispatch(
                showModal({
                    type: ERROR_MODAL,
                    props: {
                        title: 'Insufficient funds.',
                        message: `There is a fee of £${formatMoney(
                            newCardFee,
                        )} to issue a new cardholder. If you would like to discuss this further, feel free to contact us at 0330 159 0199.`,
                    },
                }),
            );
        }
    };

    return (
        <>
            {isFetching ? (
                <LoadingComponent />
            ) : (
                <CarouselContainer
                    cards={carouselCards}
                    account={account}
                    onAddCardHolderClick={handleAddNewCardHolder}
                />
            )}
            <CarouselButtonsContainer
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onViewPinClick={handleEnterCVC}
                start={isStart}
                end={isEnd}
                card={currentCard}
            />
            {!isPremiumPlus && (
                <Link
                    className="sm-button upgrade-account-button"
                    to="/more/manage-subscription"
                >
                    <i className="icon fa fa-level-up" />
                    <span>{translate('upgradeAccount')}</span>
                </Link>
            )}
        </>
    );
};

export default CardsCarouselContainer;
