export const getPostCode = results => {
    const [result] = results;
    if (!result) return {};

    const components = result.address_components;
    return components.reduce((res, item) => {
        const [type] = item.types;
        if (type === 'postal_code') {
            res['postcode'] = { ...item };
        }
        if (type === 'country') {
            res['country'] = { ...item };
        }
        if (type === 'postal_town') {
            res['town'] = { ...item };
        }
        // Fallback in case we don't find a town
        if (!res.town && type === 'locality') {
            res['town'] = { ...item };
        }
        return res;
    }, {});
};
