import React from 'react';
import dayjs from 'dayjs';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import CurrencyInput from 'components/shared/form/presentational/CurrencyInput';
import TextInput from 'components/shared/form/presentational/TextInput';
import Select from 'components/shared/form/presentational/Select';
import DatePicker from 'components/shared/form/presentational/DatePicker';
import useTranslate from 'suits-me-npm/languages/useTranslate';
import { isEmpty } from 'suits-me-npm/utils';

const StandingOrderForm = ({
    handleChange,
    handleSubmit,
    isPosting,
    ...form
}) => {
    const translate = useTranslate('standingOrders.form');
    const recurOptions = [
        { value: '1', label: translate('daily') },
        { value: '2', label: translate('weekly') },
        { value: '3', label: translate('monthly') },
        { value: '4', label: translate('yearly') },
    ];

    return (
        <Form
            onSubmit={handleSubmit}
            submitText={translate('authorise')}
            isPosting={isPosting}
            disabledButton={
                !form.amount ||
                form.amount === '£0.00' ||
                isEmpty(form.reference)
            }
        >
            <Field>
                <CurrencyInput
                    name="amount"
                    value={form.amount}
                    placeholder={translate('amount')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    prefix="£"
                />
            </Field>
            <Field name={translate('dateToStart')}>
                <DatePicker
                    name="startOn"
                    value={form.startOn}
                    placeholderText={translate('dateStart')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    minDate={dayjs().toDate()}
                />
            </Field>
            <Field name={translate('dateToEnd')}>
                <DatePicker
                    name="endOn"
                    value={form.endOn}
                    placeholderText={translate('dateEnd')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    minDate={dayjs().toDate()}
                />
            </Field>
            <Field>
                <Select
                    name="recurType"
                    value={form.recurType}
                    placeholder={translate('recurringType')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    options={recurOptions}
                />
            </Field>
            <Field>
                <TextInput
                    name="reference"
                    value={form.reference}
                    placeholder={translate('reference')}
                    classes="generic-input bg"
                    onChange={handleChange}
                />
            </Field>
        </Form>
    );
};

export default StandingOrderForm;
