import React from 'react';

import { TRANSFERWISE_MORE_DETAILS } from 'constants/shared/routes';

import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'suits-me-npm/languages/useTranslate';

const ReviewAndSend = ({ handleSubmit, isPosting, recipient, quote }) => {
    const translate = useTranslate('internationalPayment')
    return (
        <>
            <SectionTitle
                title={translate('reviewAndSend.title')}
                backRoute={TRANSFERWISE_MORE_DETAILS}
            />
            <div className="review-and-send-container">
                <div className="review-exchange-value-container">
                    <div className="exchange-value">
                        <h1>{Number(quote.sourceAmount).toFixed(2)}</h1>
                        <p>{quote.sourceCurrency}</p>
                    </div>
                    <i className="fas fa-chevron-right" />
                    <div className="exchange-value">
                        <h1>{Number(quote.targetAmount).toFixed(2)}</h1>
                        <p>{quote.targetCurrency}</p>
                    </div>
                </div>

                <div className="review-recipient-container">
                    <SectionTitle secondary title="Recipient" />
                    <div className="recipient-container">
                        <div className="recipient-avatar" />
                        <div className="recipient-details">
                            <h3>{recipient.fullName}</h3>
                            <p>{recipient.accountSummary}</p>
                        </div>
                    </div>
                </div>

                <div className="review-arrival-time-container">
                    <h3>{translate('reviewAndSend.shouldArrive')}</h3>
                    <p>{quote.formattedEstimatedDelivery}</p>
                </div>

                <div className="review-statement">
                    <p>
                        £{quote.feeTotal} {translate('reviewAndSend.feeIncluded')} {quote.rate}
                    </p>
                </div>

                <Button
                    size="medium"
                    className="center"
                    onClick={handleSubmit}
                    isPosting={isPosting}
                >
                    {translate('reviewAndSend.send')}
                </Button>
            </div>
        </>
    );
};

export default ReviewAndSend;
