import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newCardFees } from 'suits-me-npm/constants/fees';
import { cardSatusValues } from 'suits-me-npm/constants';
import { formatMoney } from 'suits-me-npm/utils';
import { dispatchShowModal } from 'utils/modals';
import { STATUS_CARD_MODAL, ERROR_MODAL } from 'constants/shared/modalTypes';
import NormalCard from '../../cardTypes/NormalCard';
import FrozenCard from '../../cardTypes/FrozenCard';
import PlaceholderCard from '../../cardTypes/PlaceholderCard';
import IssuedCard from '../../cardTypes/IssuedCard';
import NewCard from '../../cardTypes/NewCard';
import EndedCard from '../../cardTypes/EndedCard';
import { getCardsIsFetching } from 'suits-me-npm/selectors';

const { BLOCKED, ISSUED, LOST, STOLEN, DAMAGED } = cardSatusValues;

const Card = ({ card, account, position, onAddCardHolderClick }) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getCardsIsFetching);

    const handleReportStatus = () => {
        const newCardFee = newCardFees[account.productType];

        if (newCardFee <= account.clearedBalance) {
            dispatchShowModal(dispatch, STATUS_CARD_MODAL, {
                cardSerial: card.cardSerial,
                account: account,
            });
        } else {
            dispatchShowModal(dispatch, ERROR_MODAL, {
                title: 'Insufficient funds.',
                message: `There is a fee of £${formatMoney(
                    newCardFee,
                )} to issue a new cardholder. If you would like to discuss this further, feel free to contact us at 0330 159 0199.`,
            });
        }
    };

    if (!card) {
        return <PlaceholderCard />;
    }

    const { status } = card;

    if (status == BLOCKED)
        return (
            <FrozenCard
                info={{ ...card, ...account }}
                position={position}
                onClick={handleReportStatus}
            />
        );

    if (status === ISSUED)
        return <IssuedCard position={position} card={card} />;

    if (status === 'N' && !isFetching)
        return <NewCard onAddCardHolderClick={onAddCardHolderClick} />;

    if ([LOST, STOLEN, DAMAGED].includes(status))
        return <EndedCard card={card} />;

    return <NormalCard info={{ ...card, ...account }} position={position} />;
};

export default Card;
